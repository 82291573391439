import React from 'react';
import pixelore from '../assets/images/pixelore pondering.jpeg'

const About = () => {
  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp h-[78vh]">
      <div className='bg-retropb rounded-lg border-white border p-2 text-sm'>
      <img src={pixelore} alt="Pixelore" width="250"/> <strong>Name: </strong> Pixelore<br/>
      <strong>Age:</strong> 19<br/>
      <strong>Gender:</strong> Female<br/>
      <strong>Birthday:</strong> August 10<br/>
      <strong>Hobbies:</strong> Pixels, Writing, Video Games <br/>
      <br/>
      I've spent a lot of time playing Pixels, and want to share my information with everyone.<br/>
      I'm not a top gamer, but I enjoy sharing my knowledge and will try my best to update as often as possible ( *︾▽︾)<br/>
      If you ever want to know more, feel free to contact me on X <i className='text-sm'>*you can also click the Contact Me button below*</i>.      
      <br/>
      <br/>
      <p className="text-underline font-bold text-xl">Reasons to Contact Me:</p>
      🧡Request Features<br/>
      🧡Ask Questions about the game (not about a ban or bug because..cant help you)<br/>
      🧡To Collaborate<br/>
      🧡To Chat
      <br/><br/><br/>
      Hosting this website is not a cheap or free task! Help me by donating to pixelore.ron (^///^) I will forever appreciate you
      </div>

    </div>
  );
}

export default About;
