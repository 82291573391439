import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { currencyMapping } from "../utils/mappings";

const ItemModal = ({ item, onClose }) => {
  //adds esc key to close Modal
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  if (!item) return null; // If no item is selected, do not render the modal

  return (
    <div className="fixed inset-0 bg-retropb bg-opacity-60 flex justify-center items-center z-[100] text-white">
      <div className="bg-retropb p-6 rounded-lg shadow-lg w-full max-h-lvh overflow-auto md:h-fit md:max-w-[50%] lg:max-w-[30%] text-sm border border-2 border-white">
        {/* Top Section */}
        <div className="flex justify-between mb-4">
          <div className="relative inline-block group">
            <img src={item.image} alt={item.id} className="max-w-48 min-w-16 max-h-24 flex-none mr-12" />
            {item.spritesheet && !item.creator && (
              <div className="absolute top-0 left-0 mt-2 p-2 bg-retropb border border-gray-300 z-10 hidden group-hover:block">
                <img src={item.spritesheet} alt="Spritesheet" className="hidden sm:block md:max-w-[500px] md:max-h-[500px] lg:max-w-[900px] lg:max-h-[900px] mx-auto" />
              </div>
            )}
          </div>
          <div className="flex-1 text-right">
            <h2 className="text-2xl font-heading">{item.name}</h2>
            <p className="text-xs text-gray-400">{item.gamedescription || "No Game Description Available"}</p>
          </div>
        </div>
        {/* If Creator Exists (i.e. UGC) then display this portion */}
        {item.creator && (
          <div className="text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1">
            {" "}
            <>
              <div className="hidden md:block max-h-96 overflow-auto border-2 border-retrobg shadow p-1 mb-2">
                <img src={item.spritesheet} alt="Spritesheet" className="hidden sm:block md:max-w-[500px] md:max-h-[500px] lg:max-w-[900px] lg:max-h-[900px] mx-auto" />
                <br />
              </div>
              <div className="flex justify-between">
                <div>
                  <strong>
                    <i className="fa-solid fa-palette fa-xs" style={{ color: "#E294E2" }} /> Creator :{" "}
                  </strong>
                  <Link to={`/lookup/players/${item.creator}`} target="_blank" rel="noreferrer">
                    {" "}
                    {item.creatorName}
                  </Link>{" "}
                </div>
                <div>
                  {" "}
                  H: {item.sprite.height} x W: {item.sprite.width}{" "}
                </div>
              </div>
              <div>
                <strong>
                  <i className="fa-solid fa-shop fa-xs" style={{ color: "#E294E2" }} /> Market Price:
                </strong>{" "}
                {item.marketPrice !== "N/A" ? item.marketPrice + " Coins" : "N/A"}
              </div>
            </>{" "}
          </div>
        )}
        {/* If not UGC*/}
        {!item.creator && (
          <div className="mb-1 mt-2">
            <p className="text-sm text-red-200 mt-1 text-center">{item.limited ? "LIMITED ITEM --- Amount in Circulation: " + item.amount : ""}</p>
          
          {item.description && (
            <div className="text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1">
              <strong>
                <i className="fa-solid fa-pen-nib fa-xs" style={{ color: "#E294E2" }} /> PixeLore Description:
              </strong>{" "}
              {item.description || "No PixeLore Description Available"}
            </div>
          )}
          <div className="text-xs border-2 border-white bg-retrobg p-2 rounded-lg">
            <div className="justify-between flex">
              <span>
                <i className="fa-solid fa-font-awesome fa-xs" style={{ color: "#E294E2" }} /> <strong> Item Type:</strong> {item.type}
              </span>
              <span>
                {item.craftable !== "Not Craftable" && (
                  <p>
                    <strong>
                      <i className="fa-solid fa-industry" style={{ color: "#E294E2" }} /> Industry:
                    </strong>{" "}
                    {item.craftable} ( {item.tier !== "No Tier" ? "T" : ""}
                    {item.tier} )
                  </p>
                )}
              </span>
            </div>
  
            <div>
              {item.energyValue !== "N/A" && (
              <p>
                <strong className="">
                  <i className="fa-solid fa-bolt fa-xs" style={{ color: "#E294E2" }} /> Edible Energy:
                </strong>{" "}
                {item.energyValue}
              </p>)}
              <div>
                <span>
                  <strong>
                    <i className="fa-solid fa-handshake fa-xs" style={{ color: "#E294E2" }} /> Tradeable:
                  </strong>{" "}
                  {item.tradeable ? "Yes" : "No"}{" "}
                </span>
                {item.marketPrice !== "N/A" && (
                <span>
                <br/>
                  <strong>
                    <i className="fa-solid fa-shop fa-xs" style={{ color: "#E294E2" }} /> Market Price:
                  </strong>{" "}
                  {item.marketPrice} Coins
                </span>)}
              </div>
            </div>
            <div>
              <strong>
                <i className="fa-solid fa-coins fa-xs" style={{ color: "#E294E2" }} /> Where to Buy:
              </strong>
              {item.purchaseable?.length > 0
                ? item.purchaseable.map((store, index) => (
                    <span key={index}>
                      <br /> {store.store}:{" "}
                      <span className="ml-2">
                        Price: {store.buyPrice} x <img src={`/images/currencies/${store.currency}.png`} alt={store.currency} className="w-4 inline" />{" "}
                        {currencyMapping[store.currency] || store.currency}
                      </span>
                    </span>
                  ))
                : item.marketPrice !== "N/A"
                ? " Only Marketplace"
                : " Cannot be bought"}
            </div>
          </div>
          {/* Recipes where item can be used in & Recipes that craft the item */}
          <div className="flex flex-col md:flex-row md:gap-2 mt-1">
            {/* Recipes for the Items */}
            <div className="flex-1 text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1">
              <div>
                <p>
                  <i className="fa-solid fa-chalkboard-user" style={{ color: "#E294E2" }} />
                  <strong className="text-base"> Recipes:</strong>
                </p>
              </div>
              {!item.creator && item.recipes?.length > 0 ? (
                <div>
                  {item.recipes.map((recipe, index) => (
                    <div className="bg-gray-800 p-2 rounded-md shadow-md shadow-retrogray" key={index}>
                      <span className="text-sm"><strong>{recipe.name}</strong></span>
                      <br />
                     <strong>Crafting Cost:</strong> {new Intl.NumberFormat().format(recipe.totalCost)} Coins
                      <div className="ml-3">
                        <strong>Level:</strong> {recipe.level || "N/A"} -- <strong>Skill:</strong> {recipe.expType}
                        <br />
                       <i className="fa-solid fa-bolt" style={{ color: "#E294E2" }} />  {recipe.energyCost}  Energy | {recipe.exp} EXP
                        <br />
                        <strong>Craft Time:</strong> {recipe.time} Minutes
                        
                      </div>
                        <ul>
                          
                          <br />
                          <strong>Ingredients:</strong>
                          {recipe.requiredItems.map((reqItem, idx) => (
                            <li key={idx} className="ml-3">
                              <strong>{reqItem.name}</strong>: {reqItem.quantity} (Total Cost: {new Intl.NumberFormat().format(reqItem.totalCost)} Coins)
                            </li>
                          ))}
                        </ul>
                    </div>
                  ))}
                </div>
              ) : (
                "No Recipes Available for this item"
              )}
            </div>
            {/* Recipes where the item is used in */}
            <div className="flex-1 text-xs border-2 border-white bg-retrobg p-2 rounded-lg mb-1 max-h-[50vh] overflow-auto">
              <p>
                <i className="fa-solid fa-file" style={{ color: "#E294E2" }} />
                <strong className="text-base"> Used In:</strong>
              </p>
              {item.uses.length > 0 ? (
                <ul>
                  {/* Loop over `uses` and render each item */}
                  {item.uses.map((useItem, index) => (
                    <li key={index} className="bg-retropb p-1 rounded-md border border-white shadow-md shadow-retrogray text-xs">
                      {/* Render the image */}
                      <img src={useItem.achievementImage} alt={useItem.name} className="w-4 h-4 mr-2 inline" />
  
                      {/* Render the name */}
                      <span>{useItem.name}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No crafting uses found for this item.</p>
              )}
            </div>
          </div>
          </div>
        )}{" "}
        
        <button onClick={onClose} className="mt-2 px-2 py-1 bg-retropink text-white rounded-lg hover:bg-retrodp">
          Close
        </button>
      </div>
    </div>
  );
};

export default ItemModal;
