import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Pagination from "../components/Pagination";

const GuildLookup = () => {
  const [guildData, setGuildData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [guildsPerPage] = useState(20);
  const [searchQuery, setSearchQuery] = useState("");
  const [officialOnly, setOfficialOnly] = useState(false);
  const [suspiciousOnly, setSuspiciousOnly] = useState(false);
  const [minFeeFilter, setMinFeeFilter] = useState(null);
  const [maxFeeFilter, setMaxFeeFilter] = useState(null);
  const [mapCountFilter, setMapCountFilter] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const guildResponse = await axios.get("https://api.pixelore.wiki/api/guilds");
        setGuildData(guildResponse.data);
      } catch (error) {
        console.log("Issue fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleOfficialChange = (e) => {
    setOfficialOnly(e.target.checked);
  };

  const handleSuspiciousChange = (e) => {
    setSuspiciousOnly(e.target.checked);
  };

  const handleMinFeeFilterChange = (e) => {
    setMinFeeFilter(e.target.value ? parseInt(e.target.value) : null);
  };

  const handleMaxFeeFilterChange = (e) => {
    setMaxFeeFilter(e.target.value ? parseInt(e.target.value) : null);
  };

  const handleMapCountFilterChange = (e) => {
    setMapCountFilter(parseInt(e.target.value));
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const filteredGuilds = guildData.filter((guild) => {
    const matchesSearchQuery = guild.name.toLowerCase().includes(searchQuery) || guild.handle.toLowerCase().includes(searchQuery) || guild.id.toString().includes(searchQuery);

    const matchesOfficial = officialOnly ? guild.official : true;
    const matchesSuspicious = suspiciousOnly ? guild.suspicious : true;
    const matchesMinFee = minFeeFilter !== null ? guild.currentFee >= minFeeFilter : true;
    const matchesMaxFee = maxFeeFilter !== null ? guild.currentFee <= maxFeeFilter : true;
    const matchesMapCount = guild.mapCount >= mapCountFilter;

    return matchesSearchQuery && matchesOfficial && matchesMinFee && matchesMaxFee && matchesMapCount && matchesSuspicious;
  });

  // Sort the filtered guilds
  const sortedGuilds = filteredGuilds.sort((a, b) => b.pledges - a.pledges);

  // Calculate pagination
  const indexOfLastGuild = currentPage * guildsPerPage;
  const indexOfFirstGuild = indexOfLastGuild - guildsPerPage;
  const currentGuilds = sortedGuilds.slice(indexOfFirstGuild, indexOfLastGuild);

  const totalPages = Math.ceil(filteredGuilds.length / guildsPerPage);
  const maxPageNumbers = 5;
  const halfPageRange = Math.floor(maxPageNumbers / 2);

  let startPage = Math.max(1, currentPage - halfPageRange);
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  if (endPage - startPage + 1 < maxPageNumbers) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  return (
    <div className="container mx-auto mt-2 p-6 rounded-lg shadow-lg bg-retrodp md:h-[79vh]">
      <h1 className="text-6xl font-heading mb-2 text-center text-retroegg">Guild Lookup</h1>

      <div className="bg-retrogray p-2 rounded-md mb-2 text-sm">
        <input
          type="text"
          placeholder="Search by Name or Handle"
          value={searchQuery}
          onChange={handleSearch}
          className="mb-4 p-2 rounded border border-gray-400 w-full text-retropb"
        />

        <div className="filters mb-4 flex flex-col lg:flex-row md:gap-4 gap-1 justify-center items-center">
          <label className="flex items-middle text-sm">
            <input type="checkbox" checked={officialOnly} onChange={handleOfficialChange} className="mr-2" />
            Official Guilds
          </label>

          <label className="flex items-middle text-sm">
            <input type="checkbox" checked={suspiciousOnly} onChange={handleSuspiciousChange} className="mr-2" />
            Suspicious Guilds
          </label>

          <div className="flex-row md:flex-none text-center md:inline">
            <input
              type="text"
              placeholder="Min Fee"
              value={minFeeFilter || ""}
              onChange={handleMinFeeFilterChange}
              className="p-2 rounded border border-gray-400 w-[20%] lg:w-16 text-xs h-6 text-retropb"
            />
            <input
              type="text"
              placeholder="Max Fee"
              value={maxFeeFilter || ""}
              onChange={handleMaxFeeFilterChange}
              className="p-2 rounded border border-gray-400  w-[20%] lg:w-16 text-xs h-6 text-retropb"
            />
          </div>

          <div className="map-count-filters md:mt-4 lg:mt-0 flex flex-row lg:flex-row items-center text-xs">
            <span>Lands:</span>
            <input type="range" min="0" max="100" value={mapCountFilter} onChange={handleMapCountFilterChange} className="slider w-full lg:w-auto ml-2" />
            <span className="ml-3">{mapCountFilter}</span>
          </div>
        </div>
      </div>

      <div className="guilds-list space-y-4 max-h-[50vh] overflow-auto text-sm">
        {currentGuilds.map((guild) => (
          <div key={guild.id} className="guild-item flex flex-col md:flex-row items-start p-4 rounded-lg shadow-md shadow-retropb bg-retrogray">
            <div className="guild-emblem flex-shrink-0 mx-auto md:mx-0">
              <img src={guild.emblem} alt={`${guild.name} Emblem`} className="w-16 h-16 md:w-24 md:h-24 object-cover" />
            </div>
            <div className="guild-details w-full mt-4 md:mt-0 md:ml-4 text-center md:text-left">
              <h2 className="text-2xl md:text-3xl font-teko text-retroegg">{guild.name}</h2>
              <p className="mt-1">
                <strong>Description:</strong> {guild.description}
              </p>
              <div className="guild-stats mt-2 flex flex-col md:flex-row gap-2 md:gap-5">
                {guild.owner && guild.owner[0] && guild.owner[0].username && (
                  <span>
                    <strong>Owner:</strong>{" "}
                    <Link to={`/lookup/players/${guild.owner[0].username}`} target="_blank" rel="noreferrer" className="myLinks font-semibold">
                      {guild.owner[0].username}
                    </Link>
                  </span>
                )}
                <span>
                  <strong>Members:</strong> {guild.members || 0}
                </span>
                <span>
                  <strong>Pledged Members:</strong> {guild.pledges || 0}
                </span>
                <span>
                  <strong>Lands:</strong> {guild.mapCount || 0}
                </span>
                <span>
                  <strong>Shard Cost:</strong> {guild.currentFee}
                </span>
                <div className="flex justify-center lg:justify-start">
                  <span>
                    <a
                      href={`https://dashboard.pixels.xyz/guild/${guild.handle}`}
                      rel="noreferrer"
                      target="_blank"
                      className="px-1 w-10 m-1 font-body border border-dashed text-sm border-white bg-retropurple rounded-lg hover:bg-retroegg hover:text-retropurple">
                      See More
                    </a>
                    <br />
                  </span>
                  <span>
                    <Link
                      to={`/lookup/guilds/${guild.handle}`}
                      className="px-1 font-body border border-dashed text-sm border-white bg-retropurple rounded-lg hover:bg-retroegg hover:text-retropurple">
                      Guild Info
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <div className="pagination mt-6 flex justify-center">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="mx-2 p-2 border rounded bg-retrogray text-white hover:bg-retropb hover:text-retrogray"
        >
          Previous
        </button>
        {[...Array(totalPages).keys()].slice(startPage - 1, endPage).map((pageNumber) => (
          <button
            key={pageNumber + 1}
            onClick={() => paginate(pageNumber + 1)}
            className={`mx-2 p-2 border rounded ${currentPage === pageNumber + 1 ? 'bg-retropurple text-retroegg' : 'bg-retrogray text-white'} hover:bg-retropurple hover:text-retroegg`}
          >
            {pageNumber + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="mx-2 p-2 border rounded bg-retrogray text-white hover:bg-retropb hover:text-retrogray"
        >
          Next
        </button>
      </div> */}

      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} startPage={startPage} endPage={endPage} />
    </div>
  );
};

export default GuildLookup;
