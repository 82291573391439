import React, { useState, useEffect, useRef } from "react";
import BackToTop from "../components/BackToTop"; // Ensure the path is correct

const PixelAssets = () => {
  const [assets, setAssets] = useState([]);
  const [search, setSearch] = useState("");
  const containerRef = useRef(null); // Ref for the scrollable container

  useEffect(() => {
    // Fetch assets when the component mounts
    fetchPixelAssets();
  }, []);

  const fetchPixelAssets = async () => {
    try {
      const response = await fetch("https://api.pixelore.wiki/api/assets");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAssets(data);
    } catch (error) {
      console.error("Failed to fetch Assets items", error);
    }
  };

  // Filter assets based on search term
  const filteredAssets = assets
    .filter((asset) => asset.image) // Ensure image exists
    .filter((asset) => asset.id.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className="container mx-auto p-6 rounded-lg shadow-lg bg-retrodp h-[78vh] mt-5">
      <div className="md:flex">
        <h1 className="text-5xl font-heading mb-6 text-center text-retrocream md:w-3/6">In-Game Assets</h1>
        {/* Search Bar */}
        <div className="ml-auto">
          <input
            type="text"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mb-4 p-2 border rounded text-retropb ml-20 w-60 md:w-4/5"
          />
        </div>
      </div>

      <div ref={containerRef} className="overflow-auto h-[60vh]">
        {/* Fixed height and scrollable */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 text-center p-2 overflow-visible">
          {filteredAssets.map((asset) => (
            <div key={asset.id} className="relative border p-4 rounded-lg bg-retrogray group">
              <img
                src={asset.image}
                alt={asset.name || "Asset"}
                className="w-75 h-75 ml-auto mr-auto mt-auto group-hover:scale-[1.75] transition-transform duration-300 ease-in-out relative z-10"
              />
              <div className="mt-2 relative z-0">
                <div className="font-bold">{asset.name}</div>

                <div className="text-white truncate">{asset.id}</div>
                {/* Hover Effect for Description */}
                {asset.description && (
                  <div className="absolute inset-x-0 top-full mt-1 bg-gray-800 bg-opacity-75 text-white flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity">
                    <p>{asset.description}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
          {/* Back to Top Button */}
          <BackToTop scrollableContainer={containerRef.current} />
        </div>
      </div>
    </div>
  );
};

export default PixelAssets;
