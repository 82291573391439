const Pagination = ({ totalPages, currentPage, onPageChange, startPage, endPage }) => (
  <div className="flex justify-center mx-auto mt-4 w-[50%] text-sm">
    {/* Previous and First Page Buttons */}
    {currentPage > 1 && (
      <>
        <button
          onClick={() => onPageChange(1)}
          className="mx-1 p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
          aria-label="First Page"
        >
          «
        </button>
        <button
          onClick={() => onPageChange(currentPage - 1)}
          className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
          aria-label="Previous Page"
        >
          &lt;
        </button>
      </>
    )}

    {/* Page Number Buttons */}
    {startPage > 1 && (
      <>
        <button
          onClick={() => onPageChange(1)}
          className="mx-1 p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
          aria-label="First Page"
        >
          1
        </button>
        {startPage > 2 && <span className="p-2 w-7 h-7 mx-[1px] flex items-center justify-center">...</span>}
      </>
    )}
    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
      <button
        key={startPage + index}
        onClick={() => onPageChange(startPage + index)}
        className={`p-2  rounded ${startPage + index === currentPage ? 'bg-retropurple text-white' : 'bg-retrobg text-white'} w-7 h-7 mx-[1px] flex items-center justify-center`}
        aria-label={`Page ${startPage + index}`}
      >
        {startPage + index}
      </button>
    ))}
    {endPage < totalPages && (
      <>
        {endPage < totalPages - 1 && <span className="p-2 w-7 h-7 mx-[1px] flex items-center justify-center">...</span>}
        <button
          onClick={() => onPageChange(totalPages)}
          className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
          aria-label="Last Page"
        >
          {totalPages}
        </button>
      </>
    )}

    {/* Next and Last Page Buttons */}
    {currentPage < totalPages && (
      <>
        <button
          onClick={() => onPageChange(currentPage + 1)}
          className="p-2  rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
          aria-label="Next Page"
        >
          &gt;
        </button>
        <button
          onClick={() => onPageChange(totalPages)}
          className="p-2 rounded bg-retrobabypink w-7 h-7 mx-[1px] flex items-center justify-center"
          aria-label="Last Page"
        >
          »
        </button>
      </>
    )}
  </div>
);

export default Pagination;
