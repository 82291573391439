import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import GoogleAnalytics from './utils/googleAnalytics';

import Footer from './components/Footer';
import Navbar from './components/Navbar';
import NotFound from './components/NotFound';
import Home from './pages/Home';
import QuestGuides from './pages/Quests';
import StarterGuide from './pages/Starterguide';
import Taskboard from './pages/Taskboard';
import Avatars from './pages/Avatars';
import Energy from './pages/Energy';
import GuildLookup from './pages/GuildLookup';
import GuildDetail from './pages/GuildDetail';
import ItemLookup from './pages/ItemLookup';
import LandSpeck from './pages/Land-speck';
import Leaderboards from './pages/Leaderboards';
import Calculator from './pages/Calculator';
import PixelAssets from './pages/PixelAssets';
import PlayerLookup from './pages/PlayerLookup';
import Skills from './pages/Skills';
import Pets from './pages/Pets';
import Events from './pages/Events';
import About from './pages/About';
import Others from './pages/Others';
import MyCalendar from './pages/Calendar';
import BackgroundWrapper from './components/BackgroundWrapper';

function App() {
  return (
    <BackgroundWrapper>
      <Router>
      <GoogleAnalytics />
      <div className="App min-h-screen flex flex-col text-white text-xl">
        <Navbar />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/game-wiki/starter/" element={<StarterGuide />} />
            <Route path="/game-wiki/quests/" element={<QuestGuides />} />
            <Route path="/game-wiki/taskboard" element={<Taskboard />} />
            <Route path="/game-wiki/land-specks/" element={<LandSpeck />} />            
            <Route path="game-wiki/skills/" element={<Skills />} />
            <Route path="/game-wiki/events/" element={<Events />} />
            <Route path="/game-wiki/events/:tabId" element={<Events />} />
            <Route path="/game-wiki/pets/" element={<Pets />} />
            <Route path="/lookup/items" element={<ItemLookup />} />
            <Route path="/lookup/players" element={<PlayerLookup />} />
            <Route path="/lookup/players/:playerId" element={<PlayerLookup />} />
            <Route path="/lookup/guilds" element={<GuildLookup />} />
            <Route path="/lookup/guilds/:handle" element={<GuildDetail />} />
            <Route path="/tools/energy" element={<Energy />} />
            <Route path="/tools/calculator" element={<Calculator />} />
            <Route path="/tools/leaderboards" element={<Leaderboards />} />
            <Route path="/other/assets" element={<PixelAssets />} />
            <Route path="/other/avatars" element={<Avatars />} />
            <Route path="/other/calendar" element={<MyCalendar />} />
            <Route path="/about" element={<About />} />
            <Route path="/others" element={<Others />} />
            <Route path="*" element={<NotFound />} />  {/* 404 Route */}
          </Routes>
        </main>
        <Footer />
      </div>
      </Router>
    </BackgroundWrapper>
  );
}

export default App;
