import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <nav className="bg-retrobg text-retrocream p-4 font-heading shadow-lg border-b-1 border-black sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        <Link className="text-3xl" to="/">PixeLore</Link>
        <button 
          className="text-3xl lg:hidden focus:outline-none" 
          onClick={toggleMenu}
        >
          ☰
        </button>
        <ul 
          className={`lg:flex lg:space-x-4 lg:flex-wrap ${isOpen ? 'block' : 'hidden'} lg:block mt-4 lg:mt-0`}
        >
          <li className="relative group">
            <Link className="nav-link text-xl py-2 block" to="#">Wiki</Link>
            <div className="absolute hidden group-hover:block bg-retropb text-retroegg mt-1 md:mt-0 py-2 w-36 z-10 border border-white rounded-md right-0 md:left-0">
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/starter">Starter Guide</Link> 
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/quests">Quest Guides</Link>              
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/skills">Skills</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/taskboard">Task Board</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/land-specks">Land & Specks</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/pets">Pet Guides</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/game-wiki/events">Live Ops</Link>
            </div>
          </li>
          <li className="relative group">
            <Link className="nav-link text-xl py-2 block" to="#">Lookups</Link>
            <div className="absolute hidden group-hover:block bg-retropb text-retroegg mt-1 md:mt-0  py-2 w-36 z-10 border border-white rounded-md right-0 md:left-0">
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/lookup/items">Items</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/lookup/players">Players</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/lookup/guilds">Guilds</Link>
            </div>
          </li>
          <li className="relative group">
            <Link className="nav-link text-xl py-2 block" to="#">Tools</Link>
            <div className="absolute hidden group-hover:block bg-retropb text-retroegg mt-1 md:mt-0  py-2 w-36 z-10 border border-white rounded-md right-0 md:left-0">
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/tools/energy">Energy</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/tools/calculator">Calculators</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/tools/leaderboards">Leaderboards</Link>
            </div>
          </li>
          <li className="relative group">
            <Link className="nav-link text-xl py-2 block" to="#">Other</Link>
            <div className="absolute hidden group-hover:block bg-retropb text-retroegg mt-1 md:mt-0  py-2 w-36 z-10 border border-white rounded-md right-0 md:left-0">
            <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/other/avatars">Avatars</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/other/assets">Pixel Assets</Link>
              <Link className="block px-4 py-2 hover:bg-retrocream hover:text-retrogray" to="/other/calendar">Pixels Calendar</Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
