import React, { useState, useEffect } from 'react';

const BackToTop = ({ scrollableContainer }) => {
  const [visible, setVisible] = useState(false);
  
  const checkScrollTop = () => {
    if (scrollableContainer) {
      if (!visible && scrollableContainer.scrollTop > 300) {
        setVisible(true);
      } else if (visible && scrollableContainer.scrollTop <= 300) {
        setVisible(false);
      }
    }
  };

  const scrollToTop = () => {
    if (scrollableContainer) {
      scrollableContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    if (scrollableContainer) {
      scrollableContainer.addEventListener('scroll', checkScrollTop);
      return () => scrollableContainer.removeEventListener('scroll', checkScrollTop);
    }
    // eslint-disable-next-line
  }, [scrollableContainer, visible]);

  return (
    <button
      onClick={scrollToTop}
      className={`fixed bottom-4 right-4 p-3 bg-retropink text-white rounded-full shadow-lg transition-opacity ${visible ? 'opacity-100' : 'opacity-0'}`}
      style={{ transition: 'opacity 0.3s' }}
    >
      ↑
    </button>
  );
};

export default BackToTop;
