import beginnerquest1 from "../assets/images/Quests/beginnerquest1.png";
import beginnerquest2 from "../assets/images/Quests/beginnerquest2.png";

export const questsData = {
    quests: [
      // {
      //   id: ,
      //   name: "",
      //   status: "",
      //   npc: "",
      //   location: "",
      //   description: ``,
      //   items: ["None"],
      //   reward: "",
      //   otherinfo: "",
      //   image: [],
      // },
      {
        id: 28,
        name: "Barney's Bazaarn Position Decision",
        status: "expired",
        npc: "Barney, Tina",
        location: "Barney's Bazaarn",
        description: `<div class="leading-relaxed"><i class="fa-solid fa-1" style="color: #9a7997;"></i>. Head to the Bazaarn in the Northern parts of Terra Villa. Barney's in a predicament! He has a lot of jobs that need to be done.<br/>
        <i class="fa-solid fa-2" style="color: #9a7997;"></i>. Talk to Barney to get the quest and then pick a contract. You can only pick one! So choose wisely.<br/>
        <i class="fa-solid fa-message" style="color: #9a7997;"></i> For context: each contract determines what your future working for Barney will look like (your rewards and multipliers)<br/><br/>
        <table class="table1 bg-retrobg"><thead><tr><th></th><th>Picker Packer</th><th>Warehouse Warrior</th><th>Bazaarn Boss</th></tr></thead>
        <tbody>
        <tr><td>Gacha Tickets</td><td>5</td><td>10</td><td>20</td></tr>
        <tr><td>Outfit</td><td><img src="https://f003.backblazeb2.com/file/Pixelore/Quest+Data/JumpsuitIcon03-export.png" alt="Jumpsuit1" width="20" height="20"/></td><td><img src="https://f003.backblazeb2.com/file/Pixelore/Quest+Data/JumpsuitIcon02-export.png" alt="Jumpsuit2" width="20" height="20"/></td><td><img src="https://f003.backblazeb2.com/file/Pixelore/Quest+Data/JumpsuitIcon03_(1)-export.png" alt="Jumpsuit3" width="20" height="20"/></td></tr>
        <tr><td>Reputation<br/>Points</td><td colSpan="3" class="text-center">Unknown (same rep will be for all)</td></tr>
        <tr><td title="Multiplier is for the event">$PIXEL<br/>Multiplier</td><td>Small</td><td>Bigger</td><td>Biggest</td></tr>
        <tr><td>Cost</td><td>20 $PIXEL</td><td>30 $PIXEL</td><td>40 $PIXEL</td></tr>
        </tbody></table>
        <br/><i class="fa-solid fa-3" style="color: #9a7997;"></i>. After you have decided on a contract, click on Barney again and he will allow you to pick from the three contracts. Remember you can only use ONE. If you purchase multiple or buy the wrong one, you're SOL. ＞﹏＜<br/>
        <i class="fa-solid fa-4" style="color: #9a7997;"></i>. Head to Tina who is up the stairs inside the Bazaarn and on the left. She will take the contract from you and give you your Bazaarn Tickets.<br/>
        <i class="fa-solid fa-5" style="color: #9a7997;"></i>. You are now an official worker for Barney's Bazaarn! Check out our <a href="/game-wiki/events" class="text-retropink hover:text-white">events page</a> to get more information when the Bazaarn is live!~</div><br/>
        Use your new Green Gacha Clams to roll the Gacha Machine and try your luck.<br/>
        This quest is completely optional and you can still participate in the event even if you don't purchase a contract!
        <img src="https://f003.backblazeb2.com/file/Pixelore/Event+Screenshots/image_2024-09-10_225724782.png" alt="gachamachine" width="150" height="150"/>`,
        items: ["None"],
        reward: "Depends on the Contract you pick (see table above)",
        otherinfo: "September 10th 2024 - September 11th 2024",
        image: [],
      },
      {
        id: 27,
        name: "Okx Fokx Box",
        status: "expired",
        npc: "Mr. Okayecks",
        location: "Basement of the Bank",
        description: `Head to the basement of the bank and chat with Mr. Okayecks. He will give you 5 Fokx Bokx to cath 5 Fokxes. Head Back to Terra Villa and start hunting for Fokxes around. When you get close, try to use the Fokx Box to catch it! There is RNG to catching them so don't worry if they run away. Just keep trying until you catch 5. When you have 5 Fokx in a Bokx, go back to the Bank's Basement and place the boxes on the pedestals in front of Mr. Okayecks.`,
        items: ["None"],
        reward: "1 OKX Snapback Wearable and a raffle drawing for a Fokx Pet (1/5)",
        otherinfo: "August 14 2024 - August 19 2024",
        image: [],
      },{
        id: 26,
        name: "Happy Birthday, Luke!",
        status: "expired",
        npc: "Boss, Luke",
        location: "The Buck's Galore",
        description: `Go to Boss in the Buck's Galore to start the quest and get the Funky Bday Cake Recipe. Purchase the Recipe from the Shop next to Boss, and then click it from your inventory and use it on yourself. 
        <br/>Now you know how to make a birthday cake. Go find a stove. and the Cake Recipe will be a Tier 1 Recipe. <br/>
        The recipe requires 12 Clay Matrix, 6 Grainbow Flour, 6 Honey, 3 Eggs, 1 Syrup and 1 Cooking Mix. For crafting, it takes 1 minute, it gives 1 Exp, and it costs 1 Energy to Craft.<br/>
        After it's completed, take the cake back to Boss. He will then give you a Surfboard to get to Luke. Go to the right hand side of the Guild Caste (where it says Surf Station, Place Board), place the board, and head to Luke's Private Island<br/>
        There will be a small green tent. Select the cake, and click on the tent. He will then take the cake, thank you for it and give you Luke's Robe`,
        items: ["10 $PIXEL"],
        reward: "Luke Express Surfboard, Luke's Robe",
        otherinfo: "August 9 2024 - August 10 2024",
        image: [],
      },
      {
        id: 25,
        name: "Page Perry’s PIXELS Pavilion Parley",
        status: "expired",
        npc: "Page Perry",
        location: "Pixel HQ",
        description: `This was a quest where you loaded into a maze to find an X! The Maze could be in the shape of any of the letters from the word PIXELS.`,
        items: [],
        reward: "400 Reputation & Pixels Maze Trophy",
        otherinfo: "July 23 2024",
        image: [],
      },
      {
        id: 24,
        name: "Guide to Guilds: 1, 2 & 3",
        status: "expired",
        npc: "Player_W3, Gabby & Luke",
        location: "Guild HQ",
        description: `A quest for the hunt of a Power Pixel in the dungeons! This quest was a scavenger hunt of various items.`,
        items: [],
        reward: "200 Reputation, Power Pixel, Fingerprint, and a Magnifying Glass",
        otherinfo: "July 17 2024",
        image: [],
      },
      {
        id: 23,
        name: "Shorelimade for Shorelime Shades",
        status: "expired",
        npc: "Heidi",
        location: "Beach",
        description: `Craft 6 Shorelimeade  for Heidi.`,
        items: [],
        reward: "350 Reputation, Shorelime Shades",
        otherinfo: "June 20 2024",
        image: [],
      },
      {
        id: 22,
        name: "Jihoz's Shake Break",
        status: "expired",
        npc: "Jihoz",
        location: "Carnival [CH1TV] and/or Top Right Terra Villa [CH2TV]",
        description: `Craft a Cotton Candy Milkshake for Jihoz.`,
        items: [],
        reward: "250 Reputation, 1 Fishing Net",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 21,
        name: "Karen's Latte of Gratitude",
        status: "expired",
        npc: "Karen",
        location: "Karen's House [CH1TV]",
        description: `Craft a Grumpkin Spiced Latte for Karen.`,
        items: [],
        reward: "Recipe for 12 Grumpkin Spiced Lattes",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 20,
        name: "Roamin' for Ronin!",
        status: "expired",
        npc: "Ron",
        location: "Near the Fountain [CH1TV]",
        description: `Craft 10 Ronin Chains using Ronin Blocks found around Terra Villa.`,
        items: [],
        reward: "100$BERRY per Ronin Chain",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 19,
        name: "Wine Not [Popberry to Muckchuck]!",
        status: "expired",
        npc: "Goose",
        location: "Drunken Goose",
        description: `Craft 24 Bottles of Each Wine:<br/>Quest 1: Popberry<br/>Quest 2: Butterbrew<br/>Quest 3: Grainshine<br>Quest 4: Grumpkin Wine<br>Quest 5: Scarrot Wine<br>Quest 6: Watermint Whiskey<br>Quest 7:Astracactus Tequila<br>Quest 8:Hotato Hotka<br>Quest 9:Muckchuck Mead`,
        items: [],
        reward: "2 Bottles + Coin Cost of 24 Bottles<br>Quest 1 had 50 Reputation<br>Quest 2 Had 250 Reputation",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 18,
        name: "Fuy Gieri's Barbequest 1-4!",
        status: "expired",
        npc: "Fuy Gieri",
        location: "Drunken Goose [CH1TV]",
        description: `Craft BBQ Sauces, and every meal from each grill [Land, Water and Space].`,
        items: [],
        reward: "75 Reputation each for Quests 2, 3 and 4",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 17,
        name: "The Buck's Galore!",
        status: "expired",
        npc: "Hazel",
        location: "The Buck's Galore [CH1TV]",
        description: `Hazel guides you through use of the shop and explains how to get to other NPCs.`,
        items: [],
        reward: "None",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 16,
        name: "Axeing for Help",
        status: "expired",
        npc: "Lumber Jill",
        location: "South of Terra Villa [CH1TV]",
        description: `Lumber Jill teaches you how to cut wood, and asks you to cut some wood for her..`,
        items: [],
        reward: "1 Axe, 1 Energy Drink and 100 Forestry Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 15,
        name: "Sticks and Planks",
        status: "expired",
        npc: "Stan Stolan and Old Man Gurney",
        location: "South East Terra Villa [CH1TV]",
        description: `Stan Stolar asks you to craft 8 sticks, and then 1 stool for Old Man Gurney..`,
        items: [],
        reward: "20 Coins, 100 Woodworking Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 14,
        name: "Cooking with Karen",
        status: "expired",
        npc: "Karen",
        location: "Karen's House [CH1TV]",
        description: `Karen asks you to cook a Popberry Pie.`,
        items: [],
        reward: "2 Eggs, 100 Cooking Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 13,
        name: "Axeing for Help",
        status: "expired",
        npc: "Lumber Jill",
        location: "South of Terra Villa [CH1TV]",
        description: `Lumber Jill teaches you how to cut wood, and asks you to cut some wood for her..`,
        items: [],
        reward: "1 Axe, 1 Energy Drink and 100 Forestry Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 12,
        name: "Honor Bound & Even More Boxes",
        status: "expired",
        npc: "Honor",
        location: "Post Office",
        description: `Honor teaches you how to decorate a speck after you acquire one.`,
        items: [],
        reward: "1 Lithe Bush, 1 Random Rock 1, 1 Symmetrical Bush, 1 Simple Bush, 1 Imposing Bush, 1 Simple Bush (Flowering)",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 11,
        name: "Sunshine & Grainbows / Flour Powder",
        status: "expired",
        npc: "Fitz",
        location: "North West of Terra Villa",
        description: `Fitz asks you to grow 4 grainbow seeds, and then asks you to turn them into flour.`,
        items: [],
        reward: "4 Grainbow Seeds, 100 Granger Exp",
        otherinfo: "Ended with Chapter 2's release - June 17th 2024",
        image: [],
      },
      {
        id: 10,
        name: "$MOCA Public Sale Code Contest",
        status: "expired",
        npc: "Mo",
        location: "Mocaverse Club House",
        description: `Craft 3 Moca Stars for Mo.`,
        items: [],
        reward: "Chance to Win Mocaverse Whitelist.",
        otherinfo: "March 30 2024 - April 3 2024",
        image: [],
      },
      {
        id: 9,
        name: "Rescue the Seasonal Sip",
        status: "expired",
        npc: "Staff",
        location: "Carnival left of the Orange Grumpkin Machine[CH1TV]",
        description: `Harvest 12 Grumpkins for the Staff Member.`,
        items: [],
        reward: "100 Game Tickets",
        otherinfo: "October 1 - 31 2023 for Carnival",
        image: [],
      },
      {
        id: 8,
        name: "999 Shorelime Time!",
        status: "expired",
        npc: "Buck",
        location: "Terra Villa Beach",
        description: `Plant 999 Shorelimes within 24 hours!`,
        items: [],
        reward: "Shorelime Tiki",
        otherinfo: "July 26th 2023",
        image: [],
      },
      {
        id: 7,
        name: "Tastemakerz Scene Shakers!",
        status: "expired",
        npc: "Master Wabi-san, Coach, E",
        location: "West Terra Villa, Sauna and North West Terra Villa",
        description: `Deliver messages around to the different NPCs`,
        items: [],
        reward: "1 Tastemaster Burger Combo",
        otherinfo: "Was a teaser event for Tastemakerz - Lasted from April 7 2023 - May 14 2023",
        image: [],
      },
      {
        id: 6,
        name: "Steven & Todd's Bad Hare Day",
        status: "expired",
        npc: "Steven & Todd",
        location: "East and West Terra Villa",
        description: `Search for Easter Eggs all over Terra Villa and return them to Steven or Todd for a reward. Odd Eggs to Todd, Even eggs to Steven.`,
        items: [],
        reward: "$BERRY per egg",
        otherinfo: "April 4th 2023 - April 18th 2023",
        image: [],
      },
      {
        id: 5,
        name: "Luck of the Four-Leaf Clover",
        status: "expired",
        npc: "Margaret",
        location: "Terra Villa (old ch1)",
        description: `Bring 1 4-leaf clover to Margaret for a prize.`,
        items: [],
        reward: "Leprechaun Sculpture, and the ability to find pots of gold around on different Farm Maps which could be sold for 317 $BERRY each.",
        otherinfo: "Quest Lasted March 7 2023 - March 23 2023",
        image: [],
      },
      {
        id: 4,
        name: "Wine Not? Heartbeet Delivery Service",
        status: "expired",
        npc: "Goose",
        location: "The Drunken Goose",
        description: `Help Goose craft 24 Heartbeet Wines at Winona's newly-opened Winery.`,
        items: [],
        reward: "3,500 $BERRY, and an invite to the energy parties which had 240 Energy and 600 $BERRY in total",
        otherinfo: "Feb 7 2023 - Feb 21 2023",
        image: [],
      },
      {
        id: 3,
        name: "A Coinbase Christmas",
        status: "expired",
        npc: "Marcus the Mycelimancer",
        location: "Old South East Terra Villa Map",
        description: `Locate the NPC, receive a Coinbase gift box and open it up using one of the obelisks.`,
        items: [],
        reward:
          "Possible Rewards: Apiary (50), Elephant Topiary (450), Gnome1 (500), Gnome2 (500), Pond (500), Bush (1500), Iron Bar (1500). There were a total of 5000 Coinbase Boxes",
        otherinfo: "Dec 19 2022 - Dec 21 2022",
        image: [],
      },
      {
        id: 2,
        name: "Merry Merry Christmas",
        status: "expired",
        npc: "Santa Barney",
        location: "Fountain",
        description: `Santa Barney dropped all of his candy canes all over Terra Villa. He needs you to help him find them. Needed to find 19 Blue, 19 Red and 19 Green Candy Canes.`,
        items: [],
        reward: "2 Turkey Eggs, 1 Concrete Snowman 2022",
        otherinfo: "Dec 19 2022 - Dec 21 2022",
        image: [],
      },
      {
        id: 1,
        name: "Tanksgiving",
        status: "expired",
        npc: "Tom Goeble, Goose",
        location: "Thanksgiving Map",
        description: `Tom Goeble owed Goose, and he asks if you can repay Goose on your behalf.`,
        items: [],
        reward: "1 Turkey Egg, 1 Ornamental Fish Tank 2022",
        otherinfo: "Nov 23 2022 - Dec 1 2022",
        image: [],
      },{
        id: 0,
        name: "Basics with Barney",
        status: "available",
        npc: "Barney, Margaret",
        location: `Barney's Farm & Beginner Speck`,
        description: `
          I: Barney will ask you to plant a <i>Popberry Seed</i>. At each step, he is going to give you the essential tools.<br/>
          II: First put the popberry seed into the ground.<br/>
          III: Then he will give you a <i>Rusty Watering Can</i> to water the seed.<br/>
          IV: When it has been watered, Barney will provide you with a <i>Basic Shears</i>. After 10 seconds, you can clip that Popberry out of the ground.<br/>
          V: Next, he'll give you 6 more popberry seeds! Follow Steps II - IV again for all 6 of them.<br/>
          VI: After you've harvested all of those seeds, Barney will give you a <i>Basic Axe</i> to chop some trees. Take the Axe from your bag, pick a tree and start whacking it.<br/>
          VII: When you've finished cutting down a tree, talk to Barney again. He'll commend you and send you off to the house.<br/>
          VIII: Once in the house, talk to Margaret. Add one of the logs you cut down to the stove next to Margaret.<br/>
          IX: The stove will be lit, and Margaret will ask you to create one <i>Popberry Jam</i>. Popberry Jams are the longest part of the process as they take 2 minutes each<br>(╥﹏╥).<br/>
          X: When you're done, Barney and Margaret will send you on your way to your own Speck. Barney will give you 24 Popberry seeds, and you'll get a Beginner Infinifunnel Task for 5 Popberry Jams!<br/>
          XI: Use the knowledge you've learnt up to this point, plant your seeds, craft your Jam, and when you've submitted all 5 Jams, Barney will reach out again!<br/>
          XII: This time, Barney's back to give you a <i>Basic Pick</i>. Head outside of your house and use the Pick on the grey <i>Mine</i> outside until you get something from it<br/>
          XIII: You're now free to go wherever you want. Explore Terra Villa, talk to NPCs, visit other lands and players. Go have fun!<br/>
        `,
        items: ["None needed, everything is provided"],
        reward: `30 Coins`,
        otherinfo: `See more about your Speck <a href='/game-wiki/land-specks'>here</a>`,
        image: [beginnerquest1, beginnerquest2],
      }
    ],
  };