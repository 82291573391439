import React, { useEffect, useState } from "react";
import { fetchItems } from "../utils/fetchItemData";
import ItemModal from "../components/itemModal";

const Energy = () => {
  const [energyItems, setEnergyItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: "energyPerCoin", direction: "descending" });
  const [hideNoCost, setHideNoCost] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDailyEnergyVisible, setIsDailyEnergyVisible] = useState(false);
  const [items, setItems] = useState([]);  
  const [selectedItem, setSelectedItem] = useState(null);
  
  useEffect(() => {
    // Fetch the items only once (or on some condition)
    const fetchInitialItems = async () => {
      await fetchItems(setItems); // This should only set 'items' once
    };
  
    fetchInitialItems();
  }, []);
  
  useEffect(() => {
    const fetchData = () => {
      try {  
        // Filter items based on energy value
        const filteredItems = items.filter(
          (item) => item.energyValue && item.energyValue > 0
        );
  
        // Update the energyItems state with filtered items
        setEnergyItems(filteredItems);
      } catch (err) {
        console.error(err);
      }
    };
  
    if (items.length > 0) {
      fetchData(); // Only process if items are available
    }
  }, [items]);
  

  const sortItems = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }

    const sortedItems = [...energyItems].sort((a, b) => {
      const aValue = key === "marketPrice" || key === "energyPerCoin"
        ? a[key] === "N/A" ? Number.MAX_VALUE : parseFloat(a[key].toString().replace(/,/g, ''))
        : parseFloat(a[key].toString().replace(/,/g, ''));
      const bValue = key === "marketPrice" || key === "energyPerCoin"
        ? b[key] === "N/A" ? Number.MAX_VALUE : parseFloat(b[key].toString().replace(/,/g, ''))
        : parseFloat(b[key].toString().replace(/,/g, ''));
      
      if (aValue < bValue) return direction === "ascending" ? -1 : 1;
      if (aValue > bValue) return direction === "ascending" ? 1 : -1;
      return 0;
    });

    setSortConfig({ key, direction });
    setEnergyItems(sortedItems);
  };

  const getArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "ascending" ? "▲" : "▼";
    }
    return "";
  };

  const filteredItems = energyItems
    .filter((item) => (hideNoCost ? item.marketPrice !== "N/A" : true))
    .filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const handleItemClick = (item) => {
      setSelectedItem(item);
    };

    const handleCloseModal = () => {
      setSelectedItem(null);
    };
  

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp h-[77vh] overflow-scroll">
      <h1 className="text-5xl font-heading mb-6 text-center text-retroegg">Energy</h1>
      
      {/* Collapsible Daily Energy Section */}
      <div className="mb-4">
      <div className="bg-retropb rounded-md p-2 mt-2">
        <h2 
          className="starterTitle cursor-pointer"
          onClick={() => setIsDailyEnergyVisible(!isDailyEnergyVisible)}
        >
          Other Energy <span className="text-xs">{isDailyEnergyVisible ? "See Less" : "See More"}</span>
        </h2>
        {isDailyEnergyVisible && (
          <p className="starterText">
            These are other energy constraints. VIP Sauna requires being VIP:
            <br />
            ⚡Regular Energy Regen: 2E/min [8.3 Hours for 1000 Energy]
            <br />
            ⚡Sauna Energy: 365E for an hour [Stay online in the sauna pool for 1 hour to receive the recharge] [This is around 240E more than regular E regen]
            <br/>
            ⚡⚡⚡For the Sauna Energy, you get this every 23 hours. And if you didn't use all from the previous day, then you can get the balance from that day, then refresh and you can get your energy for the new day also!
            <br />
            ⚡VIP Energy: 1000E every 6 hours [Just click the sauna rocks for an instant recharge]
            <br />
            ⛓️‍💥You lose 0.5E when you change maps (not refresh, just going to a new map)
          </p>
        )}
      </div></div>

      <div className="bg-retropb rounded-md p-2 mt-2">
      <h2 className="starterTitle">Energy Items</h2>
      <div className="starterText">
      <div className="mb-4 text-left max-w-[100%]">
      <input
          type="text"
          placeholder="Search items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="p-1 border border-gray-300 rounded-md text-sm mr-4 md:w-48 w-full h-8"
        />
        <label className="md:mt-0 mt-2">
          <input
            type="checkbox"
            checked={hideNoCost}
            onChange={() => setHideNoCost(!hideNoCost)}
            className="mr-2"
          />
          Hide N/A
        </label>        
      </div>


      <div className="overflow-y-auto max-h-[400px]">
      <table className="tablelb w-full text-retrocream">
        <thead className="sticky">
          <tr>
            <th onClick={() => sortItems("name")}>
              Item {getArrow("name")}
            </th>
            <th onClick={() => sortItems("energyValue")}>
              Energy {getArrow("energyValue")}
            </th>
            <th onClick={() => sortItems("marketPrice")}>
              Cost {getArrow("marketPrice")}
            </th>
            <th onClick={() => sortItems("energyPerCoin")}>
              E/Coin {getArrow("energyPerCoin")}
            </th>
            <th onClick={() => sortItems("coinPerEnergy")}>
              Coin/E {getArrow("coinPerEnergy")}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item) => (
            <tr key={item.id}>
              <td onClick={() => handleItemClick(item)} className="cursor-zoom-in">{item.name}</td>
              <td>⚡{item.energyValue}</td>
              <td>🪙{item.marketPrice}</td>
              <td>{item.coinPerEnergy ? `⚡/🪙 ${item.coinPerEnergy.toLocaleString()}` : "N/A"}</td>
              <td>{item.energyPerCoin ? `🪙/⚡ ${item.energyPerCoin.toLocaleString()}` : "N/A"}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
      </div>
      {selectedItem && <ItemModal item={selectedItem} onClose={handleCloseModal} />}
    </div>
  );
};

export default Energy;
