import React, { useState, useEffect, useCallback, useMemo } from "react";
import pixelore from "../assets/images/pixeloreevent.jpeg";
import { fetchItems } from "../utils/fetchItemData";
import axios from "axios";
import ItemModal from "./itemModal";
import { Link, useNavigate, useParams } from "react-router-dom";

const BarneysBazaarn = () => {
  const navigate = useNavigate();
  const { tabId } = useParams();
  const [activeTab, setActiveTab] = useState(tabId || "");

  const [items, setItems] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [lbData, setLbData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterIndustry, setFilterIndustry] = useState("All");
  const [tierFilter, setTierFilter] = useState("All");
  const [amount, setAmount] = useState("1");
  const [playerNameFilter, setPlayerNameFilter] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  useEffect(() => {
    fetchItems(setItems);
  }, []);

  const openModal = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  const getTierByName = useCallback((name) => {
    if (name.toLowerCase().startsWith("refined")) return 3;
    if (name.toLowerCase().startsWith("elite")) return 4;
    if (name.toLowerCase().startsWith("improved")) return 2;
    return 1; // Basic
  }, []);

  const parseCost = (cost) => parseFloat(cost.replace(/,/g, "")) || 0;

  const skills = useMemo(
    () => [
      { type: "cookboxing", industry: "Cooking" },
      { type: "mineboxing", industry: "Mining" },
      { type: "businessboxing", industry: "Business" },
      { type: "metalboxing", industry: "Metalworking" },
      { type: "stoneboxing", industry: "Stoneshaping" },
      { type: "forestboxing", industry: "Forestry" },
      { type: "animalboxing", industry: "Animal Care" },
      { type: "farmboxing", industry: "Farming" },
      { type: "woodboxing", industry: "Woodworking" },
    ],
    []
  );

  useEffect(() => {
    if (items && Array.isArray(items)) {
      // Map to store costs by type and tier
      const costMap = {};

      // Points by tier mapping
      const pointsByTier = { 1: 1, 2: 2, 3: 4, 4: 8 };

      // First, process the items to populate the costMap
      items.forEach((item) => {
        const itemType = skills.find((skill) => skill.type === item.craftable)?.industry || "Unknown Industry";
        const tier = getTierByName(item.name || "");
        costMap[itemType] = { ...costMap[itemType], [tier]: parseCost(item.craftingCost) };
      });

      // Calculate the filteredBoxData
      const filteredBoxData = items
        .filter((item) => item.craftable && skills.some((skill) => skill.type === item.craftable) && item.name)
        .map((item) => {
          const itemType = skills.find((skill) => skill.type === item.craftable)?.industry || "Unknown Industry";
          const tier = getTierByName(item.name || "");
          const costToCraft = parseCost(item.craftingCost);

          // Calculate total cost with previous tiers
          let previousCost = 0;
          for (let t = 1; t < tier; t++) {
            previousCost += parseFloat(costMap[itemType][t]) || 0;
          }
          const totalCost = costToCraft + previousCost;

          // Calculate Points Per Coin (ppc) or Coins Per Point (cpc)
          const points = pointsByTier[tier] || 0;
          const ppc = totalCost / points || 0;

          return {
            itemNameText: item.name || "Unnamed Item",
            itemName: (
              <span key={item.id} onClick={() => openModal(item)} className="cursor-zoom-in">
                <img src={item.image} alt={item.name || "No name"} style={{ width: "20px", marginRight: "10px" }} />
                {item.name || "Unnamed Item"}
              </span>
            ),
            itemTier: tier,
            itemIndustry: itemType,
            itemIngredients: item.recipes?.[0]?.requiredItems
              ? item.recipes[0].requiredItems.map((reqItem) => `${reqItem.quantity} x ${reqItem.name}`).join("<br />")
              : "No Ingredients",
            costToCraft: costToCraft,
            costOfPreviousBoxes: previousCost,
            totalCostWithPreviousTiers: totalCost,
            craftTime: item.recipes?.[0]?.time || 0,
            ppc: ppc, // Points per coin
            energy: item.recipes?.[0]?.energyCost,
          };
        });

      setBoxData(filteredBoxData);
    }
  }, [items, getTierByName, skills]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ldbResponse = await axios.get("https://api.pixelore.wiki/api/bazaarnlb");
        // Calculate ranks and update state
        const playersWithRanks = ldbResponse.data.playersDescending.map((entry, index) => ({
          ...entry,
          rank: index + 1, // Assign rank starting from 1
        }));
        setLbData({ playersDescending: playersWithRanks });
      } catch (error) {
        console.log("Issue fetching data: ", error);
      }
    };

    if (activeTab === "lbs") {
      fetchData();
    }
  }, [activeTab]);

  // Filter players based on the search query
  const filteredPlayers = useMemo(() => {
    return lbData.playersDescending ? lbData.playersDescending.filter((entry) => entry.player.username.toLowerCase().includes(playerNameFilter.toLowerCase())) : [];
  }, [lbData, playerNameFilter]);

  const tabs = [
    {
      id: "summary",
      label: "Event Summary",
      content: `<h2 class="text-4xl font-eventHeading underline">Summary of the Event</h2>
      <h2 class="text-3xl font-eventHeading underline">What is Barney's Bazaarn</h2>
                <p class="mt-1">Barney's Bazaarn is one of the events in Pixels's repertoire of cycling events that offers more ways to play the game, have fun and earn prizes. Barney's Bazaarn is a solo-play event, doesn't require a guild, and is completely free to play!</p><br/>
                <h2 class="text-3xl font-eventHeading underline">What is the objective?</h2>
                <p>The objective of the game is to gather as much points as possible within your shift. You get 3 shifts of 5 minutes each per day where customers roll in and request items. Provide the correct items to each customer to make them happy and gain points.</p><br/>
                <h2 class="text-3xl font-eventHeading underline">Where can I get started?</h2>
                <p>Head to Barney's Bazaarn located North East of the Pixel HQ. (see image below)</p>
                <img src="https://f003.backblazeb2.com/file/Pixelore/Event+Screenshots/bazaarnlocation.png" class="h-72" />
                <h2 class="text-3xl font-eventHeading underline">What is the lore behind the game?</h2>
                <p>Barney is a Clamazon Reseller that needs to get 8,000+ packages packaged and shipped out! He is hiring new employees to aid and assist with getting all the orders out. Get hired, and get packaging!</p>
                <h2 class="text-3xl font-eventHeading underline">What categories are there?</h2>
                <p>There's a category for every skill within the game:</p>
                <p><ul class="basicLists"><li>Mining</li><li>Farming</li><li>Forestry</li><li>Animal Care</li><li>Business</li><li>Metalworking</li><li>Stoneshaping</li><li>Cooking</li><li>Woodworking</li></ul></p>`,
    },
    {
      id: "howto",
      label: "How To Play",
      content: `<div><h2 class="text-4xl font-eventHeading underline">How do I Play?</h2>
      <h2 class="text-3xl font-eventHeading underline">Box Items</h2>
      <p>After you've entered Barney's Bazaarn, your first priority should be to start boxing some materials! You only get 5 minutes to serve customers, so do not start your shift right away..<br/>
      Head to the palette with boxes on top of it to purchase some Base Bazaarn boxes. Each one costs <b>400 <img src="/images/currencies/cur_coins.png" alt="Coins" class="h-3 inline"/> Coins</b>.
      <img src="https://f003.backblazeb2.com/file/Pixelore/Event+Screenshots/image_2024-09-10_193612560.png" class="h-40"/></p>
      <p>First, you need to make sure you have the ingredients. Head to each conveyor belt to get the full details or check out our list breakdown on the left.</p>
      <p>When you have all the ingredients necessary for the box, select the conveyor belt for that skill, and select the item you want to craft. The machine will start to process the box.<br/>
      A <tier 1 box takes 4 minutes, a tier 2 box takes 3 minutes, tier 3 box takes 2 minutes and a tier 1 box takes 1 minute. So if you want to craft tier 4 boxes, you need 10 minutes per tier 4 box since you need the previous box for each higher tier box.<br/>
      You can purchase speed boost vouchers from Tina's Computer to speed up the boxes. </p>
      <h2 class="text-3xl font-eventHeading underline">Start Your Shift</h2>
      <p>You can play all 3 of your shifts back to back in a 24-hour period if you want to! But after that, you can't play another shift unless you have a Shift Coupon!<br/>
      <img src="https://f003.backblazeb2.com/file/Pixelore/Event+Screenshots/image_2024-09-05_024437205.png" class="h-40"/>
      Head to the Time Clock Machine and click on it. This will start your shift immediately! Customers will start to come in to the tables at the entryway. Each one gives you 1 minute to deliver their order, and multiple can show up at the same time.<br/>
      <img src="https://f003.backblazeb2.com/file/Pixelore/Event+Screenshots/image_2024-09-05_032024123.png" class="h-40"/>
      If you're a Land Owner, there are two more possible customers on the right side. If you're a VIP player, there are two more possible customers on the left side.<br/>
      <br/>
      The best tip for tracking boxes is to follow colors. The colors of the customer's order will match the color of the related box. They might also request some $PIXEL items like wallpaper, flooring, infiniportables or flowers!</p>
      <h2 class="text-3xl font-eventHeading underline">Earn Points</h2>
      <p>Every box you deliver correctly earns you points. Here's a breakdown of the kind of points you can earn:
      <ul class="basicLists">
      <li>A correct Tier 1 Box - 1 Point</li>
      <li>A correct Tier 2 Box - 2 Points</li>
      <li>A correct Tier 3 Box - 4 Points</li>
      <li>A correct Tier 4 Box - 8 Points</li>
      <li>A $PIXEL Item Box - 8 Points</li>
      <li>A Question Mark Box - 1 Point (do not give your high tier boxes to Fuy Gieri)</li></ul></p>
      </div>`,
    },
    {
      id: "boxes",
      label: "List of Boxes",
      content: `<h2 class="text-3xl font-eventHeading underline">Box Data for each Category</h2>`,
    },
    {
      id: "lbs",
      label: "Leaderboards",
      content: ``,
    },
    {
      id: "prize1",
      label: "Leaderboard Prizes",
      content: `<h2 class="text-3xl font-eventHeading underline">Leaderboard Prizes</h2> More Info Coming Soon!<br/>
      So far, all we know is the top 5,000 will receive $PIXEL prizes! We don't know the amounts (๑•̀ㅂ•́)و✧`,
    },
    {
      id: "prize2",
      label: "Green Gacha Prizes",
      content: `<h2 class="text-3xl font-eventHeading underline">Green Clam Gacha Prizes!</h2>
                <img src="https://f003.backblazeb2.com/file/Pixelore/Event+Screenshots/image_2024-09-10_225724782.png" alt="Gacha" class="h-60 p-2 outline outline-retrogray rounded-lg mb-2"/>
                <p>Use the Green Gacha Clams in the Clam Machine to collect prizes!</p>
                <ul class="basicLists">
                <li>Tier 1 Boxes</li>
                <li>Tier 2 Boxes</li>
                <li>Tier 3 Boxes</li>
                <li>Tier 4 Boxes</li>
                <li>Barney D-40 (auto-completes any crafting)</li>
                <li>Shift Slips (Provides an extra shift for your day - normally only 3)</li>
                <li>Farm Charm Crystal</li>
                <li>Energy Drinks</li>
                <li>Football</li>
                <li>Moo Munch</li>
                <li>Cow Turd</li>
                <li>Pixels Blanket</li>
                <li>Purple Plum Wallpaper</li>
                <li>Checkered Blanket</li>
                <li>Infiniportable</li>
                <li>Astra, Aqua and Terra Flower Seeds</li>
                <li>Astra, Aqua and Terra Flowers</li>
                <li>Green Gacha Clams (To use in the Clam Machine)</li>
               <li>Grand Gold Gacha Clams (For Use after the event is over)</li></ul>`,
    },
    {
      id: "prize3",
      label: "Golden Gacha Prizes",
      content: `<h2 class="text-3xl font-eventHeading underline">Gold Clam Gacha Prizes</h2><p>Use the Golden Gacha Clams to collect prizes after the events are over!<br/>There will be a Basement Barncade where you can win prizes like:</p>
                <ul class="basicLists">
                <li>10 NFT Lands</li>
                <li>300 Cow Pets</li>
                <li>30 Bazaarn Quonset Huts</li>
                <li>BarnBQ Grill</li>
                <li>Barney's Snapback</li>
                <li>Bazaarn Storage Boxes</li>
                <li>Barney's Shoes</li>
                <li>Bazaarn Wallpaper</li>
                <li>Bazaarn Trophy</li>
                <li>Barney's Bed Blanket</li>
                <li>Barney's Bolstered Bed</li>
                <li>Barney's Wardrobe</li>
                <li>Barney's Painting</li>
                <li>Barney's Bazaarn Door Portal</li>
                </ul>`,
    },
  ];

  //handlers
  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleFilterChange = (e) => setFilterIndustry(e.target.value);
  const handleTierChange = (e) => setTierFilter(e.target.value);
  const handleAmountChange = (e) => setAmount(e.target.value);

  // Filter and sort boxData
  const filteredAndSortedData = boxData
    .filter(
      (item) =>
        item.itemNameText.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (filterIndustry === "All" || item.itemIndustry === filterIndustry) &&
        (tierFilter === "All" || item.itemTier.toLocaleString() === tierFilter)
    )
    .sort((a, b) => {
      // Handle sorting for the selected column
      if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

  useEffect(() => {
    setActiveTab(tabId || ""); // Set the tab based on the URL parameter
  }, [tabId]);

  const handleTabClick = (id) => {
    setActiveTab(id);
    navigate(`/game-wiki/events/${id}`); // Update the URL when a tab is clicked
  };

  const getContent = (id) => {
    const tab = tabs.find((tab) => tab.id === id);
    return tab ? tab.content : "Content Not Found";
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const handleSort = (column) => {
    const newDirection = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortDirection(newDirection);
  };

  return (
    <div className="bg-retropb rounded-md p-2 mt-2 relative">
      <h2 className="starterTitle">Barney's Bazaar</h2>
      <div className="starterText">
        {/* Mobile toggle button */}
        <div className="block md:hidden">
          <button onClick={toggleSidebar} className="p-1 bg-retrogray text-white rounded text-xs m-2">
            {isSidebarOpen ? "-" : "+"}
          </button>
        </div>

        {/* Sidebar and Backdrop */}
        {isSidebarOpen && (
          <>
            {/* Backdrop to cover content when sidebar is open */}
            <div className="fixed inset-0 bg-black opacity-60 z-40" onClick={toggleSidebar}></div>

            {/* Sidebar */}
            <div className="fixed top-30 align-middle left-0 h-2/4 w-3/4 bg-retrogray text-white z-50 p-4 border-r border-gray-300" style={{ maxWidth: "250px" }}>
              <ul>
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <button
                      className={`tab-button ${tab.id === activeTab ? "active" : ""}`}
                      onClick={() => {
                        handleTabClick(tab.id);
                        toggleSidebar(); // Close sidebar after selecting a tab
                      }}>
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}

        <div className="flex">
          {/* Sidebar for desktop */}
          <div className="hidden md:block w-[200px] border-r border-gray-300 pr-2 mr-2">
            <ul>
              {tabs.map((tab) => (
                <li key={tab.id}>
                  <button
                    className={`tab-button w-full text-left p-2 rounded-md transition-colors duration-200 m-1 ${
                      tab.id === activeTab
                        ? "bg-gray-500 text-white" // Active tab styles
                        : "bg-retrobg text-white hover:bg-gray-400" // Inactive tab styles
                    }`}
                    onClick={() => handleTabClick(tab.id)}>
                    {tab.label}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Content Area */}
          <div className="w-full md:ml-4">
            {activeTab ? (
              activeTab === "boxes" ? (
                <div>
                  <h2 class="text-3xl font-eventHeading underline">Individual Box Data</h2>
                  <div className="mb-4">
                    {/* Search Input */}
                    <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder="Search items" className="p-2 text-xs border rounded" />

                    {/* Filter Dropdown */}
                    <select value={filterIndustry} onChange={handleFilterChange} className="p-1 text-xs border rounded ml-2">
                      <option value="All">All Industries</option>
                      <option value="Cooking">Cooking</option>
                      <option value="Farming">Farming</option>
                      <option value="Metalworking">Metalworking</option>
                      <option value="Stoneshaping">Stoneshaping</option>
                      <option value="Business">Business</option>
                      <option value="Animal Care">Animal Care</option>
                      <option value="Mining">Mining</option>
                      <option value="Forestry">Forestry</option>
                      <option value="Woodworking">Woodworking</option>
                    </select>

                    <select value={tierFilter} onChange={handleTierChange} className="p-1 text-xs border rounded ml-2">
                      <option value="All">All Tiers</option>
                      <option value="1">Tier 1</option>
                      <option value="2">Tier 2</option>
                      <option value="3">Tier 3</option>
                      <option value="4">Tier 4</option>
                    </select>

                    <label htmlFor="text" className="inline text-xs p-2 font-bold">
                      Boxes:{" "}
                      <input type="text" value={amount} onChange={handleAmountChange} placeholder="Amount of Boxes" className="font-normal w-12 p-2 text-xs border rounded" />
                    </label>
                  </div>

                  <div className=" overflow-x-auto overflow-y-auto max-h-[55vh]">
                    {isModalOpen && <ItemModal item={selectedItem} onClose={closeModal} />}
                    {/* Render Table */}
                    <table className="tablelb">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("itemNameText")}>
                            Item Name
                            {sortColumn === "itemNameText" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("itemIndustry")}>
                            Industry
                            {sortColumn === "itemIndustry" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("itemTier")}>
                            Tier
                            {sortColumn === "itemTier" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("craftTime")}>
                            Craft Time
                            {sortColumn === "craftTime" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("energy")}>
                            Energy Cost
                            {sortColumn === "energy" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("itemIngredients")}>
                            Ingredients
                            {sortColumn === "itemIngredients" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("costToCraft")}>
                            Cost of Ingredients
                            {sortColumn === "costToCraft" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("costOfPreviousBoxes")}>
                            Previous Boxes
                            {sortColumn === "costOfPreviousBoxes" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("totalCostWithPreviousTiers")}>
                            Total Cost
                            {sortColumn === "totalCostWithPreviousTiers" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                          <th onClick={() => handleSort("ppc")}>
                            Coins Per Point
                            {sortColumn === "ppc" && (sortDirection === "asc" ? "↑" : "↓")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredAndSortedData.length > 0 ? (
                          filteredAndSortedData.map((item) => (
                            <tr key={item.id}>
                              <td>{item.itemName}</td>
                              <td>{item.itemIndustry}</td>
                              <td>{item.itemTier}</td>
                              <td>{item.craftTime} min</td>
                              <td>{item.energy}</td>
                              <td dangerouslySetInnerHTML={{ __html: item.itemIngredients }}></td>
                              <td>
                                <img src="/images/currencies/cur_coins.png" alt="Coins" className="w-4 inline" /> {(item.costToCraft * amount).toLocaleString()}
                              </td>
                              <td>
                                <img src="/images/currencies/cur_coins.png" alt="Coins" className="w-4 inline" /> {(item.costOfPreviousBoxes * amount).toLocaleString()}
                              </td>
                              <td>
                                <img src="/images/currencies/cur_coins.png" alt="Coins" className="w-4 inline" /> {(item.totalCostWithPreviousTiers * amount).toLocaleString()}
                              </td>
                              <td>{item.ppc.toLocaleString()}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="10">No items found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : activeTab === "lbs" ? (
                <div className="overflow-auto h-[50vh]">
                  <h2 class="text-3xl font-eventHeading underline">Leaderboards</h2>
                  {/* Name Filter for Leaderboards */}
                  <input type="text" placeholder="Filter by player name" onChange={(e) => setPlayerNameFilter(e.target.value)} className="p-1 border rounded mb-2 w-60" />
                  {filteredPlayers.length > 0 ? (
                    <table className="tablelb w-full">
                      <thead>
                        <tr>
                          <th className="border px-4 py-2">#</th>
                          <th className="border px-4 py-2">Player Name</th>
                          <th className="border px-4 py-2">Points</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredPlayers
                          .filter((entry) => entry.player.username.toLowerCase().includes(playerNameFilter.toLowerCase()))
                          .map((entry, index) => (
                            <tr key={entry.player._id}>
                              <td className="border px-4 py-2">{entry.rank}</td>
                              <td className="border px-4 py-2 hover:text-retropink">
                                <Link to={`/lookup/players/${entry.player._id}`} target="_blank" rel="noreferrer">
                                  {entry.player.username}
                                </Link>
                              </td>
                              <td className="border px-4 py-2">{entry.value}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No players found</p>
                  )}
                </div>
              ) : (
                // Render content for other tabs
                <div dangerouslySetInnerHTML={{ __html: getContent(activeTab) }} />
              )
            ) : (
              // Default content if no tab is selected
              <div>
                <img src={pixelore} alt="Pixelore" className="h-60 rounded-3xl" />
                <p className="text-lg mt-2">Please select a menu item on the left to view content.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BarneysBazaarn;
