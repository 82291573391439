import React, { useEffect, useState, useMemo } from "react";
import Pagination from "../components/Pagination";
import { Link } from 'react-router-dom';
import LoadingSpinner from "../components/LoadingSpinner";

const Leaderboards = () => {
  const [players, setPlayers] = useState([]);
  const [guilds, setGuilds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [playersPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGuild, setSelectedGuild] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: "exp.overall.level", direction: "desc" });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        setLoading(true);
        const response = await fetch("https://api.pixelore.wiki/api/players");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setPlayers(data);

        const uniqueGuilds = [...new Set(data.map(player => player.guildId))].sort();
        setGuilds(uniqueGuilds);
      } catch (error) {
        console.error("Failed to fetch player data", error);
        setError("Failed to fetch player data.");
      } finally {
        setLoading(false);  // Set loading to false when fetching ends
      }
      
    };

    fetchPlayerData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleGuildChange = (event) => {
    setSelectedGuild(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedPlayers = useMemo(() => {
    return [...players].sort((a, b) => {
      if (sortConfig.key) {
        const aValue = sortConfig.key.split(".").reduce((o, i) => o?.[i], a);
        const bValue = sortConfig.key.split(".").reduce((o, i) => o?.[i], b);

        // If both values are numbers, handle them with numerical sorting
        if (!isNaN(aValue) && !isNaN(bValue)) {
          const aNum = aValue !== undefined && aValue !== null ? parseFloat(Number(aValue).toFixed(2)) : -Infinity;
          const bNum = bValue !== undefined && bValue !== null ? parseFloat(Number(bValue).toFixed(2)) : -Infinity;

          if (aNum < bNum) return sortConfig.direction === "asc" ? -1 : 1;
          if (aNum > bNum) return sortConfig.direction === "asc" ? 1 : -1;
          return 0;
        }

        // If either value is not a number, handle them with string sorting
        const aStr = aValue !== undefined && aValue !== null ? String(aValue).toLowerCase() : "";
        const bStr = bValue !== undefined && bValue !== null ? String(bValue).toLowerCase() : "";

        if (aStr < bStr) return sortConfig.direction === "asc" ? -1 : 1;
        if (aStr > bStr) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      }
      return 0;
    }).map((player, index) => ({
      ...player,
      rank: index + 1, // Assign the rank based on the sorted position
    }));;
  }, [players, sortConfig]);

  const filteredPlayers = useMemo(() => {
    return sortedPlayers.filter((player) =>
      player.username?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedGuild === '' || player.guildId === selectedGuild)
    );
  }, [sortedPlayers, searchTerm, selectedGuild]);

  const indexOfLastPlayer = currentPage * playersPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - playersPerPage;
  const currentPlayers = filteredPlayers.slice(indexOfFirstPlayer, indexOfLastPlayer);

  const totalPages = Math.ceil(filteredPlayers.length / playersPerPage);
  const pageRange = 2; // Number of pages to show around the current page
  const startPage = Math.max(1, currentPage - pageRange);
  const endPage = Math.min(totalPages, currentPage + pageRange);

  const getSortArrow = (key) => {
    return sortConfig.key === key ? (sortConfig.direction === "asc" ? "↑" : "↓") : "";
  };  

  return (
    <div className="container mx-auto mt-5 p-6 rounded-lg shadow-lg bg-retrodp">
      <h1 className="text-5xl font-heading text-center text-retroegg">Leaderboards</h1>
      <h3 className="text-md font-heading text-center text-retroegg">Updates Daily - Only Guild-Pledged Players</h3>
      {error && <p className="text-red-500 text-center">{error}</p>}
      <div className="flex justify-between mb-4">
        <input type="text" placeholder="Search Player" value={searchTerm} onChange={handleSearchChange} className="p-1 text-xs border border-gray-300 rounded h-7 text-retropb" />
        <select
          value={selectedGuild}
          onChange={handleGuildChange}
          className="p-1 text-xs border border-gray-300 rounded h-7 text-retropb"
        >
          <option value="">Select Guild</option>
          {guilds.map((guild) => (
            <option key={guild} value={guild} className="p-1 text-xs border border-gray-300 rounded h-5 text-retropb">
              {guild}
            </option>
          ))}
        </select>
      </div>
      
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="overflow-scroll h-[60vh]">
      <table className="tablelb2">
        <thead>
          <tr>
          <th>Rank</th>
            <th onClick={() => handleSort("username")}>Player Name {getSortArrow("username")}</th>
            <th onClick={() => handleSort("guildId")}>Guild {getSortArrow("guildId")}</th>
            <th onClick={() => handleSort("coinInventory.cur_coins")}>
              <img src={`/images/currencies/cur_coins.png`} alt="Coins" />{getSortArrow("coinInventory.cur_coins")}
            </th>
            <th onClick={() => handleSort("coinInventory.cur_pixel")}>
              <img src={`/images/currencies/cur_pixel.png`} alt="Pixels" />{getSortArrow("coinInventory.cur_pixel")}
            </th>
            <th onClick={() => handleSort("exp.farming.level")}>
              <img src={`/images/skills/farming.png`} alt="Farming" />{getSortArrow("exp.farming.level")}
            </th>
            <th onClick={() => handleSort("exp.cooking.level")}>
              <img src={`/images/skills/cooking.png`} alt="Cooking" />{getSortArrow("exp.cooking.level")}
            </th>
            <th onClick={() => handleSort("exp.forestry.level")}>
              <img src={`/images/skills/forestry.png`} alt="Forestry" />{getSortArrow("exp.forestry.level")}
            </th>
            <th onClick={() => handleSort("exp.woodwork.level")}>
              <img src={`/images/skills/woodwork.png`} alt="Woodwork" />{getSortArrow("exp.woodwork.level")}
            </th>
            <th onClick={() => handleSort("exp.mining.level")}>
              <img src={`/images/skills/mining.png`} alt="Mining" />{getSortArrow("exp.mining.level")}
            </th>
            <th onClick={() => handleSort("exp.stoneshaping.level")}>
              <img src={`/images/skills/stoneshaping.png`} alt="Stoneshaping" />{getSortArrow("exp.stoneshaping.level")}
            </th>
            <th onClick={() => handleSort("exp.metalworking.level")}>
              <img src={`/images/skills/metalworking.png`} alt="Metalworking" />{getSortArrow("exp.metalworking.level")}
            </th>
            <th onClick={() => handleSort("exp.petcare.level")}>
              <img src={`/images/skills/petcare.png`} alt="Animal Care" />{getSortArrow("exp.petcare.level")}
            </th>
            <th onClick={() => handleSort("exp.business.level")}>
              <img src={`/images/skills/business.png`} alt="Business" />{getSortArrow("exp.business.level")}
            </th>
            <th onClick={() => handleSort("exp.overall.level")}>Overall</th>
          </tr>
        </thead>
        <tbody>
          {currentPlayers.map((player, index) => (
            <tr key={index}>
              <td>{player.rank}</td>
              <td><Link to={`/lookup/players/${player.uid}`} target="_blank" rel='noreferrer' className="hover:text-retropink">{player.username}</Link></td>
              <td><Link to={`/lookup/guilds/${player.guildId}`} target="_blank" rel='noreferrer' className="hover:text-retropink">{player.guildId}</Link></td>
              <td>{player.coinInventory?.cur_coins?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td>{player.coinInventory?.cur_pixel?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td>{player.exp?.farming?.level || 0}</td>
              <td>{player.exp?.cooking?.level || 0}</td>
              <td>{player.exp?.forestry?.level || 0}</td>
              <td>{player.exp?.woodwork?.level || 0}</td>
              <td>{player.exp?.mining?.level || 0}</td>
              <td>{player.exp?.stoneshaping?.level || 0}</td>
              <td>{player.exp?.metalworking?.level || 0}</td>
              <td>{player.exp?.business?.level || 0}</td>
              <td>{player.exp?.petcare?.level || 0}</td>
              <td>{player.exp?.overall?.level || 0}</td>
            </tr>
          ))}
      
        </tbody>
      </table></div>
      )}
      <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={setCurrentPage} startPage={startPage} endPage={endPage} />
    </div>
  );
};

export default Leaderboards;
