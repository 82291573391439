import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function GuildDetail() {
  const { handle } = useParams();
  const [guild, setGuild] = useState(null);
  const [filter, setFilter] = useState("Pledged");
  const [searchTerm, setSearchTerm] = useState("");
  const [feeAddress, setFeeAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [shardSales, setShardSales] = useState(null);
  const [accountBalance, setAccountBalance] = useState(0);
  const [view, setView] = useState("default");

  useEffect(() => {
    fetch(`https://api.pixelore.wiki/api/guilds/${handle}`)
      .then((response) => response.json())
      .then((data) => setGuild(data))
      .catch((error) => console.error("Error fetching guild:", error));
  }, [handle]);

  useEffect(() => {
    if (guild && guild.guild) {
      setFeeAddress(guild.guild.feeAddress);
      setTokenId(guild.guild.tokenId);
    }
  }, [guild]);

  useEffect(() => {
    const fetchGuildBalance = async () => {
      try {
        const response = await fetch(`https://api.pixelore.wiki/api/guildBalance?feeAddress=${feeAddress}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAccountBalance(data.treasuryResults.result.ronNetWorth.toFixed(2));
      } catch (error) {
        console.error("Error fetching guild balance:", error);
      }
    };

    if (feeAddress) {
      fetchGuildBalance();
    }
  }, [feeAddress]); // Only runs when feeAddress changes and is not empty

  useEffect(() => {
    const fetchShardSales = async () => {
      try {
        const response = await fetch(`https://api.pixelore.wiki/api/shardSales/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tokenId }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setShardSales(data.shardResults.items);
      } catch (error) {
        console.error("Error fetching shard sales:", error);
      }
    };

    if (tokenId) {
      fetchShardSales();
    }
  }, [tokenId]); // Only runs when tokenId changes and is not empty

  if (!guild) {
    return <div className="container mx-auto mt-10 p-6 rounded-lg shadow-lg bg-retrodp">Loading...</div>;
  }

  const filteredMembers = guild.guildMembers.filter((member) => {
    const matchesRole = (() => {
      switch (filter) {
        case "Owner":
          return member.role === "Owner";
        case "Member":
          return member.role === "Member";
        case "Admin":
          return member.role === "Admin";
        case "Worker":
          return member.role === "Worker";
        case "Pledged":
          return member.stakedShards > 0;
        case "Pledged without Role":
          return member.stakedShards > 0 && !member.role;
        case "Supporter":
          return member.role === "Supporter";
        default:
          return true; // Show all members
      }
    })();

    const matchesSearch = member.player.username.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesRole && matchesSearch;
  });

  const simplifyId = (id) => {
    if (id.startsWith("nftHouse")) {
      const number = id.replace("nftHouse", "");
      return `House #${number}`;
    } else if (id.startsWith("pixelsNFTFarm-")) {
      const number = id.replace("pixelsNFTFarm-", "");
      return `Land #${number}`;
    } else {
      return id; // Return the original id if it doesn't match any patterns
    }
  };

  const modifyAddress = (address, maxLength = 10) => {
    if (!address) return "";
    if (address === "0x0000000000000000000000000000000000000000") return "Pixels";
    return address.length > maxLength ? `${address.slice(0, 8)}...${address.slice(-3)}` : address;
  };

  return (
    <div className="container mx-auto mt-2 p-6 rounded-lg shadow-lg bg-retrodp text-sm h-fit">
      <div className="p-4 h-fit bg-retrogray rounded-sm border border-white border-2">
        <h1 className="absolute text-xl font-teko mb-6 text-left text-retropink ">
          <Link to="/lookup/guilds">BACK</Link>
        </h1>
        <div className="flex justify-center">
          <div className="md:flex justify-center w-full  overflow-hidden md:w-3/4 bg-retrobg p-4 rounded-md border-retropink border border-1 shadow-lg">
            <div className="my-auto">{guild.guild.emblem && <img src={guild.guild.emblem} alt={guild.guild.handle} className="w-20 h-20 rounded-md mr-2" />}</div>
            <div>
              <p>
                <strong>Handle:</strong> {guild.guild.handle}
              </p>
              <p>
                <strong>Name:</strong> {guild.guild.name}
              </p>
              <p>
                <strong>Treasury Address:</strong>{" "}
                <a href={`https://app.roninchain.com/address/${guild.guild.feeAddress}`} target="blank" rel="noreferrer" className="myLinks">
                  <span className="text-sm">{guild.guild.feeAddress}</span>
                </a>
              </p>
              <p>
                <strong>Treasury Balance:</strong> ${accountBalance}USD
              </p>
              <p>
                <strong>Token ID:</strong>{" "}
                <a href={`https://app.roninchain.com/token/0x7ac8de4b2d37ac6136be285ee7060e8676c9644f/${guild.guild.tokenId}`} target="blank" rel="noreferrer" className="myLinks">
                  <span className="text-sm">{guild.guild.tokenId}</span>
                </a>
              </p>
              <p>
                <strong>Shards:</strong> {guild.guild.membershipsCount}
              </p>
              <p>
                <strong>Pledged Members:</strong> {guild.guild.maxMembers}
              </p>
              <p>
                <strong>Lands:</strong> {guild.guild.mapCount}
              </p>
              <p>
                <strong>Whitelist Only:</strong> {guild.guild.useWhitelist ? "Yes" : "No"}
              </p>
            </div>
            <div>
              {guild.guild.official && <i className="fa-solid fa-stamp text-green-400" />}
              {guild.guild.suspicious && <i className="fa-solid fa-exclamation-triangle text-red-500" />}
            </div>
          </div>
        </div>
        <div className="flex justify-center m-2">
          <button onClick={() => setView(view === "default" ? "shards" : "default")} className="bg-retropink text-white px-3 h-6 text-xs rounded-md">
            {view === "default" ? "View Shard Movement" : "View Members & Lands"}
          </button>
        </div>

        <div className="flex justify-center">
          <div className={`flex w-full justify-center transition-transform duration-500 ease-in-out ${view === "shards" ? "rotate-y-180" : ""}`}>
            {view === "default" ? (
              <div className="flex flex-col md:flex-row w-full md:w-3/4 gap-2 bg-retrobg p-4 rounded-md border-retropink border border-1 shadow-lg">
                {/* Collapsible Members List */}
                <div className="w-full flex-none lg:flex-row md:w-1/4 md:mr-5 text-center bg-retrocream rounded-lg p-3 shadow-retrobabypink shadow-md border border-retropb">
                  <h1 className="text-3xl text-retropb">Guild Members</h1>
                  <div className="mt-2">
                    <div className="flex flex-col mb-2">
                      {/* Filter Dropdown */}
                      <div>
                        <select
                          id="member-filter"
                          className="text-retropb text-xs h-5 rounded bg-retroegg border-retrogray border-2 shadow-sm shadow-retropink w-full"
                          value={filter}
                          onChange={(e) => setFilter(e.target.value)}>
                          <option value="All">All</option>
                          <option value="Member">Member</option>
                          <option value="Admin">Admin</option>
                          <option value="Owner">Owner</option>
                          <option value="Worker">Worker</option>
                          <option value="Pledged">Pledged</option>
                          <option value="Pledged without Role">Pledged without Role</option>
                          <option value="Supporter">Supporter</option>
                        </select>
                        {/* Search Input */}
                        <input
                          type="text"
                          placeholder="Search by name"
                          className="text-sm h-5 p-2 rounded bg-retroegg text-retropb hover:text-retrobg border-retrogray border-2 shadow-sm shadow-retropink w-full"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2 h-80 overflow-scroll bg-retrogray p-1 pl-2 rounded-md border border-1 border-gray-500 text-left">
                      <ul>
                        {filteredMembers.map((member) => (
                          <li key={member._id}>
                            <Link to={`/lookup/players/${member.player.username}`} target="_blank" rel="noreferrer" className="hover:text-retropink">
                              {member.player.username}
                            </Link>{" "}
                            [{member.membershipsCount}]
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Collapsible Lands List */}
                <div className="flex-auto md:w-60 text-center bg-retrocream rounded-lg p-3 shadow-retrobabypink shadow-md border border-retropb">
                  <h1 className="text-3xl text-retropb">GuiLd Lands</h1>
                  <div className="h-96 overflow-scroll px-1 py-2 rounded-md">
                    <table className="min-w-full tablelb bg-retrodp">
                      <thead>
                        <tr>
                          <th className="px-4 py-2">Land</th>
                          <th className="px-4 py-2">Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guild.maps.lands.map((land) => (
                          <tr key={land._id}>
                            <td className="border-y-2 py-2 border-white">{simplifyId(land.id)}</td>
                            <td className="border-y-2 py-2 border-white">
                              <Link to={`/lookup/players/${land.ownerAddress}`} target="_blank" rel="noreferrer" className="hover:text-retropink">
                                {land.name}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col md:flex-row w-full md:w-3/4 gap-2 bg-retrobg p-4 rounded-md border-retropink border border-1 shadow-lg">
                <div className="w-full bg-retrocream p-4 rounded-md border-retropink border border-1 shadow-lg">
                  <h1 className="text-3xl text-retropb">Shard Transfer Data</h1>
                  <h3 className="text-lg text-retropb font-teko">100 Most Recent Transactions</h3>
                  <div className="h-96 overflow-scroll px-1 py-2 rounded-md bg-retrogray">
                    {/* Display the shard sales data here */}
                    <table className="table1 w-full bg-retrobg">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>TX ID</th>
                          <th>Amt</th>
                          <th>To</th>
                          <th>From</th>
                        </tr>
                      </thead>
                      <tbody>
                        {shardSales.map((shard, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              <a href={`https://app.roninchain.com/tx/${shard.transactionHash}`} target="_blank" rel="noreferrer">
                                {modifyAddress(shard.transactionHash)}
                              </a>
                            </td>
                            <td className="text-center w-5">{shard.value}</td>
                            <td>
                              <a href={`https://app.roninchain.com/address/${shard.from}`} target="_blank" rel="noreferrer">
                              {modifyAddress(shard.from)} {shard.from !== "0x0000000000000000000000000000000000000000" && (<span className="text-retropurple">(<Link to={`/lookup/players/${shard.from}`}>Pixels Account</Link>)</span>)}
                              </a>
                            </td>
                            <td>
                              <a href={`https://app.roninchain.com/address/${shard.to}`} target="_blank" rel="noreferrer">
                              {modifyAddress(shard.to)} {shard.to !== "0x0000000000000000000000000000000000000000" && (<span className="text-retropurple">(<Link to={`/lookup/players/${shard.to}`}>Pixels Account</Link>)</span>)}
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuildDetail;
