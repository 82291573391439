import React, { useEffect, useState, useRef, useMemo } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchItems } from "../utils/fetchItemData";
import Select from "react-select";
import ItemModal from "../components/itemModal";
import Pagination from "../components/Pagination";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ItemLookup = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null); //modal
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [searchTerm, setSearchTerm] = useState(query.get('search') || ''); //search filter
  const [craftables, setCraftables] = useState([]); //industry filter
  const [selectedCraftable, setSelectedCraftable] = useState(query.get('craftable') || ''); //industry filter
  const [selectedIndustry, setSelectedIndustry] = useState(query.get('industry') || ''); //industry filter
  const [types, setTypes] = useState([]); //category filter
  const [typeOptions, setTypeOptions] = useState([]); //category filter
  const [selectedTypes, setSelectedTypes] = useState(query.getAll('types') || []); //category filter
  const [selectedCreator, setSelectedCreator] = useState(query.get('creator') || ''); //ugc creator filter
  const [ugcCreators, setUGCCreators] = useState([]); //ugc creator filter
  const [obtainables, setObtainables] = useState([]); //How to Obtain item filter
  const [selectedObtainable, setSelectedObtainable] = useState(query.get('obtainable') || '');
  const [limitedFilterState, setLimitedFilterState] = useState(parseInt(query.get('limited') || '0', 10)); // 0: show all, 1: exclude no tier, 2: exclude tier
  const [tierFilterState, setTierFilterState] = useState(parseInt(query.get('tier') || '0', 10)); // 0: show all, 1: exclude no tier, 2: exclude tier
  const [currentPage, setCurrentPage] = useState(parseInt(query.get('page') || '1', 10)); //Pagination
  const itemsPerPage = 50; //Pagination
  const [sortColumn, setSortColumn] = useState("profit"); //Sort Feature
  const [sortOrder, setSortOrder] = useState("desc"); //Sort Feature
  const selectRef = useRef(null); //to clear the category filter properly

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchTerm) params.set('search', searchTerm);
    if (selectedCraftable) params.set('craftable', selectedCraftable);
    if (selectedIndustry) params.set('industry', selectedIndustry);
    if (selectedTypes.length > 0) params.delete('types'); // Remove existing types
    selectedTypes.forEach(type => params.append('types', type));
    if (selectedCreator) params.set('creator', selectedCreator);
    if (selectedObtainable) params.set('obtainable', selectedObtainable);
    params.set('tier', tierFilterState);
    params.set('limited', limitedFilterState);
    params.set('page', currentPage);

    navigate({ search: params.toString() });
  }, [
    searchTerm, selectedCraftable, selectedIndustry, selectedTypes, selectedCreator,
    selectedObtainable, tierFilterState, limitedFilterState, currentPage, navigate
  ]);

  const handleItemClick = (item) => setSelectedItem(item);
  const handleCloseModal = () => setSelectedItem(null);
  const handleCreatorChange = (selectedOption) => setSelectedCreator(selectedOption.value);
  const handleTierFilterToggle = () => setTierFilterState((prev) => (prev + 1) % 3); // Cycles through 0, 1, 2
  const handleLimitedFilterLogic = () => setLimitedFilterState((prev) => (prev + 1) % 3); // Cycles through 0, 1, 2
  const handleCraftableChange = (selectedOption) => setSelectedCraftable(selectedOption.value);
  const handleObtainableChange = (selectedOption) => setSelectedObtainable(selectedOption.value);
  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const handleTypeChange = (selectedOptions) => setSelectedTypes(selectedOptions.map((option) => option.value));

  const handleSort = (column) => {
    const order = sortColumn === column && sortOrder === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortOrder(order);
  };

  useEffect(() => {
    fetchItems(setItems, setCraftables, setTypes, setUGCCreators, setObtainables);
  }, []);

  useEffect(() => {
    const options = types.map((type) => ({ value: type, label: type }));
    setTypeOptions([...options]);
  }, [types]);

  const filteredItems = useMemo(() => {
    return items
      .filter((item) => item.name && (item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.id.toLowerCase().includes(searchTerm.toLowerCase())))
      .filter(
        (item) => !selectedCraftable || selectedCraftable === "" || (selectedCraftable === "craftable" ? item.craftable !== "Not Craftable" : item.craftable === selectedCraftable)
      )
      .filter((item) => !selectedIndustry || item.craftable?.toLowerCase() === selectedIndustry.toLowerCase())
      .filter(
        (item) =>
          selectedTypes.length === 0 || selectedTypes.includes("all") || selectedTypes.some((selectedType) => item.type.split(",").some((type) => type.trim() === selectedType))
      )
      .filter((item) => {
        if (tierFilterState === 1) return item.tier !== "No Tier"; // Exclude items with no tier
        if (tierFilterState === 2) return item.tier === "No Tier" || !item.tier; // Exclude items with a tier
        return true; // Show all items when tierFilterState is 0
      })
      .filter((item) => {
        if (limitedFilterState === 1) return item.limited; // Exclude items that are not limited
        if (limitedFilterState === 2) return !item.limited; // Exclude items that are limited
        return true; // Show all items
      })
      .filter((item) => !selectedObtainable || selectedObtainable === "" || (item.obtainable && item.obtainable.includes(selectedObtainable)))
      .filter((item) => !selectedCreator || item.creatorName === selectedCreator)
      .sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        const isANaOrNull = valueA === "N/A" || valueA === null;
        const isBNaOrNull = valueB === "N/A" || valueB === null;

        if (isANaOrNull && isBNaOrNull) return 0;
        if (isANaOrNull) return 1;
        if (isBNaOrNull) return -1;

        const numA = parseFloat(valueA.toString().replace(/,/g, ""));
        const numB = parseFloat(valueB.toString().replace(/,/g, ""));

        const isANumber = !isNaN(numA);
        const isBNumber = !isNaN(numB);

        if (isANumber && isBNumber) {
          return sortOrder === "asc" ? numA - numB : numB - numA;
        } else if (isANumber) {
          return sortOrder === "asc" ? -1 : 1;
        } else if (isBNumber) {
          return sortOrder === "asc" ? 1 : -1;
        } else {
          const valueALower = valueA !== null ? valueA.toLowerCase() : "";
          const valueBLower = valueB !== null ? valueB.toLowerCase() : "";
          return sortOrder === "asc" ? valueALower.localeCompare(valueBLower) : valueBLower.localeCompare(valueALower);
        }
      });
  }, [items, searchTerm, selectedCraftable, selectedIndustry, selectedTypes, selectedCreator, sortColumn, sortOrder, tierFilterState, limitedFilterState, selectedObtainable]);

  const totalItems = filteredItems.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const startPage = Math.max(1, currentPage - 2);
  const endPage = Math.min(totalPages, currentPage + 2);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [totalPages, currentPage]);

  const clearAllFilters = () => {
    setSearchTerm("");
    setSelectedCraftable("");
    setSelectedIndustry("");
    setSelectedTypes([]);
    setSelectedCreator("");
    setCurrentPage(1);
    setTierFilterState(0);
    setLimitedFilterState(0);
    setSelectedObtainable("");
  
    if (selectRef.current) {
      selectRef.current.clearValue();
    }
  
    // Update URL to reflect cleared filters
    const params = new URLSearchParams();
    navigate({ search: params.toString() });
  };

  return (
    <div className="justify-center items-start">
      <div className="container mx-auto p-3 rounded-lg shadow-lg bg-retrodp mt-2">
        <h1 className="text-5xl font-heading mb-2 text-center text-retrocream">Item Lookup</h1>

        <button onClick={() => setFiltersVisible(!filtersVisible)} className="p-1 rounded text-white text-xs hover:bg-retrogray">
          {filtersVisible ? "-" : "+"}
        </button>
        {filtersVisible && (
          <div className="bg-retrobabypink p-2 rounded-lg text-center text-retropb mb-2">
            <div className="container mx-auto p-1 text-sm">
              <div className="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-2">
                <input
                  type="text"
                  placeholder="Search by name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="p-2 border rounded w-full flex-1"
                />
                <Select
                  onChange={handleCraftableChange}
                  value={selectedCraftable ? { label: selectedCraftable, value: selectedCraftable } : { label: "All Industries", value: "" }}
                  options={[
                    { label: "All Industries", value: "" },
                    { label: "Craftable", value: "craftable" },
                    ...craftables.map((craftable) => ({
                      label: craftable,
                      value: craftable,
                    })),
                  ]}
                  className="text-retropb text-xs m-1 z-[40] w-full flex-1"
                />
                <Select
                  ref={selectRef}
                  isMulti
                  options={typeOptions}
                  onChange={handleTypeChange}
                  placeholder="Item categories..."
                  className="w-full z-[36] flex-1 text-xs"
                  classNamePrefix="select"
                />
                <Select
                  onChange={handleCreatorChange}
                  value={selectedCreator ? { label: selectedCreator, value: selectedCreator } : { label: "UGC Creators...", value: "" }}
                  options={[
                    { label: "UGC Creators...", value: "" }, // Add an "All" option with an empty value
                    ...ugcCreators.map((creator) => ({
                      label: creator,
                      value: creator,
                    })),
                  ]}
                  className="text-retrogray text-xs m-1 z-30 w-full flex-1"
                  placeholder="UGC Creators..."
                />
                <Select
                  onChange={handleObtainableChange}
                  value={selectedObtainable ? { label: selectedObtainable, value: selectedObtainable } : { label: "Methods to Acquire..", value: "" }}
                  options={obtainables.map((obtainable) => ({
                    label: obtainable,
                    value: obtainable,
                  }))}
                  className="text-retrogray text-xs m-1 z-30 w-full flex-1"
                  placeholder="Methods to Acquire.."
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row items-center justify-between space-y-2 md:space-y-0 md:space-x-2 md:ml-10 md:mr-10 text-xs">
              <div className="flex gap-2">
                <button onClick={handleLimitedFilterLogic} className="px-4 py-2 md:w-60 bg-retrogray text-white rounded-md">
                  {limitedFilterState === 0 && (
                    <>
                      {" "}
                      <i className="fa-solid fa-circle-dot fa-xs" style={{ color: "#c2a8c1" }} /> Showing all items (Limited & Unlimited){" "}
                    </>
                  )}
                  {limitedFilterState === 1 && (
                    <>
                      {" "}
                      <i className="fa-regular fa-circle-dot fa-xs" style={{ color: "#c2a8c1" }} /> Showing Items that are Limited{" "}
                    </>
                  )}
                  {limitedFilterState === 2 && (
                    <>
                      {" "}
                      <i className="fa-solid fa-circle-notch fa-xs" style={{ color: "#c2a8c1" }} /> Showing Items that are not Limited{" "}
                    </>
                  )}
                </button>
                <button onClick={handleTierFilterToggle} className="px-4 py-2 bg-retrogray text-white rounded-md md:w-60">
                  {tierFilterState === 0 && (
                    <>
                      {" "}
                      <i className="fa-solid fa-circle-dot fa-xs" style={{ color: "#c2a8c1" }} /> Showing all items (Tier & No Tier)
                    </>
                  )}
                  {tierFilterState === 1 && (
                    <>
                      {" "}
                      <i className="fa-regular fa-circle-dot fa-xs" style={{ color: "#c2a8c1" }} /> Showing Only Items with A Tier
                    </>
                  )}
                  {tierFilterState === 2 && (
                    <>
                      {" "}
                      <i className="fa-solid fa-circle-notch fa-xs" style={{ color: "#c2a8c1" }} /> Showing Only Items with No Tiers
                    </>
                  )}
                </button>
              </div>
              <button onClick={clearAllFilters} className="p-2 mx-1 rounded bg-retrodeeppink text-white  text-xs hover:bg-retrogray w-full md:w-auto">
                Clear All
              </button>
            </div>
          </div>
        )}

        <div className="overflow-auto h-[60vh]">
          <table className="tablelb w-full">
            <thead>
              <tr>
                <th onClick={() => handleSort("name")}>Name {sortColumn === "name" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("tier")}>Tier {sortColumn === "tier" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("level")}>Levels {sortColumn === "level" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("craftable")}>Industry {sortColumn === "craftable" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("type")}>Type {sortColumn === "type" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("energyValue")}>Energy {sortColumn === "energyValue" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("craftingCost")}>Cost {sortColumn === "craftingCost" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th onClick={() => handleSort("marketPrice")}>MP {sortColumn === "marketPrice" && (sortOrder === "asc" ? "↑" : "↓")}</th>
                <th>Store</th>
                <th onClick={() => handleSort("profit")}>Profit {sortColumn === "profit" && (sortOrder === "asc" ? "↑" : "↓")}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id}>
                  <td className="hover:text-retropink cursor-zoom-in" onClick={() => handleItemClick(item)}>
                    <img src={item.image} alt={item.name} className="h-5 w-5 inline" /> {item.name}
                  </td>
                  <td>{item.tier}</td>
                  <td>{item.level}</td>
                  <td>{item.craftable}</td>
                  <td>{item.type}</td>
                  <td>{item.energyValue}</td>
                  <td>{item.craftingCost}</td>
                  <td>{item.marketPrice}</td>
                  <td>
                    {item.purchaseable?.length > 0 ? (
                      <>
                        {item.purchaseable[0].buyPrice}{" "}
                        <img src={`/images/currencies/${item.purchaseable[0].currency}.png`} alt={item.purchaseable[0].currency} className="h-1 inline" />{" "}
                      </>
                    ) : null}
                  </td>
                  <td>{item.profit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} startPage={startPage} endPage={endPage} />
      </div>
      {selectedItem && <ItemModal item={selectedItem} onClose={handleCloseModal} />}
    </div>
  );
};

export default ItemLookup;
